import isNil from 'lodash/isNil';
import round from 'lodash/round';

import { UNIT_SYMBOL_TO_PRECISION } from 'constants/precision';
import { DEFAULT_PRECISION_IMPERIAL, DEFAULT_PRECISION_METRIC } from 'constants/samplePoint';
import {
  CM_TO_IN,
  HA_TO_AC,
  HPA_TO_IN_HG,
  KPA_TO_PSI,
  L_TO_GAL,
  MI_TO_KM,
  ML_TO_AC_FT,
  MM_TO_IN,
  M_TO_FT,
  SQM_TO_FT_2
} from 'constants/unit-conversion';
import { UnitType, getUnitSymbolByCountry } from 'utils/get-unit-by-country';
import roundNumber from 'utils/round-number';

/**
 * @deprecated Please use convertMetricToImperialAlwaysRounding instead. Upgrade existing usage need careful testing.
 * Convert metric to imperial when the output is for a US enterprise. Decimal
 * places apply ONLY when the country is US and defaults to 2.
 * */
export default function convertMetricToImperial(
  unitType: UnitType,
  country = 'AUS',
  value?: number,
  /** Decimal places apply only when the country is US */
  decimalPlaces = DEFAULT_PRECISION_IMPERIAL
): number | undefined {
  if (!value || country !== 'USA') {
    return value;
  }
  let convertedValue: number;
  switch (unitType) {
    case UnitType.LEVEL_SM:
    case UnitType.TREND_SM:
      convertedValue = value * CM_TO_IN;
      break;
    case UnitType.LEVEL_MD:
    case UnitType.TREND_MD:
      convertedValue = value * M_TO_FT;
      break;
    case UnitType.RAIN:
      convertedValue = value * MM_TO_IN;
      break;
    case UnitType.VOLUME_SM:
      convertedValue = value * L_TO_GAL;
      break;
    case UnitType.VOLUME_LG:
      convertedValue = value * ML_TO_AC_FT;
      break;
    case UnitType.FLOW:
      convertedValue = value * L_TO_GAL;
      break;
    case UnitType.SIZE:
      convertedValue = value * HA_TO_AC;
      break;
    case UnitType.PRESSURE_LG:
      convertedValue = value * KPA_TO_PSI;
      break;
    case UnitType.AREA_SM:
      convertedValue = value * SQM_TO_FT_2;
      break;
    default:
      convertedValue = value;
      break;
  }

  return decimalPlaces >= 0
    ? roundNumber(convertedValue, { decimalPlaces })
    : convertedValue;
}

type ConvertibleUnitTypes =
  | UnitType.LEVEL_SM
  | UnitType.LEVEL_MD
  | UnitType.RAIN
  | UnitType.VOLUME_SM
  | UnitType.TREND_SM
  | UnitType.TREND_MD
  | UnitType.FLOW
  | UnitType.SIZE
  | UnitType.PRESSURE_MD
  | UnitType.PRESSURE_LG
  | UnitType.TEMPERATURE
  | UnitType.WIND_SPEED;

/**
 * If `precision` is not provided, the util will try to determine it based on unit. If it fails to do so, the fallback
 * is DEFAULT_PRECISION_METRIC and DEFAULT_PRECISION_IMPERIAL.
 */
export function convertMetricToImperialAlwaysRounding(
  unitType: ConvertibleUnitTypes,
  country = 'AUS',
  metricValue?: number,
  precision?: number
): number | undefined {
  if (isNil(metricValue)) {
    return metricValue;
  }
  const unitSymbol = getUnitSymbolByCountry({ unitType, country });
  const unitPrecision: number | undefined = precision ?? UNIT_SYMBOL_TO_PRECISION[unitSymbol];

  if (country !== 'USA') {
    return round(metricValue, unitPrecision ?? DEFAULT_PRECISION_METRIC);
  }
  let imperialValue: number;
  switch (unitType) {
    case UnitType.LEVEL_SM:
    case UnitType.TREND_SM:
      imperialValue = metricValue * CM_TO_IN;
      break;
    case UnitType.LEVEL_MD:
    case UnitType.TREND_MD:
      imperialValue = metricValue * M_TO_FT;
      break;
    case UnitType.RAIN:
      imperialValue = metricValue * MM_TO_IN;
      break;
    case UnitType.VOLUME_SM:
      imperialValue = metricValue * L_TO_GAL;
      break;
    case UnitType.FLOW:
      imperialValue = metricValue * L_TO_GAL;
      break;
    case UnitType.SIZE:
      imperialValue = metricValue * HA_TO_AC;
      break;
    case UnitType.PRESSURE_MD:
      imperialValue = metricValue * HPA_TO_IN_HG;
      break;
    case UnitType.PRESSURE_LG:
      imperialValue = metricValue * KPA_TO_PSI;
      break;
    case UnitType.TEMPERATURE:
      imperialValue = metricValue * 9 / 5 + 32;
      break;
    case UnitType.WIND_SPEED:
      imperialValue = metricValue * MI_TO_KM;
      break;
    default:
      imperialValue = metricValue;
      break;
  }

  return round(imperialValue, unitPrecision ?? DEFAULT_PRECISION_IMPERIAL);
}

export const isUnitTypeConvertible = (unitType: string): unitType is ConvertibleUnitTypes => {
  return [
    UnitType.LEVEL_SM,
    UnitType.LEVEL_MD,
    UnitType.RAIN,
    UnitType.VOLUME_SM,
    UnitType.TREND_SM,
    UnitType.TREND_MD,
    UnitType.FLOW,
    UnitType.SIZE,
    UnitType.PRESSURE_MD,
    UnitType.PRESSURE_LG,
    UnitType.TEMPERATURE,
    UnitType.WIND_SPEED
  ].includes(unitType as ConvertibleUnitTypes);
};