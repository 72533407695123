import { useEnterpriseCountry } from 'store/modules/enterprise/hooks';
import { LevelDisplayUnit, VolumeDisplayUnit } from 'types/models/samplePoint';

export const SymbolToUnit = {
  cm: {
    name: {
      singular: 'Centimetre',
      plural: 'Centimetres'
    }
  },
  in: {
    name: {
      singular: 'Inch',
      plural: 'Inches'
    }
  },
  m: {
    name: {
      singular: 'Metre',
      plural: 'Metres'
    }
  },
  ft: {
    name: {
      singular: 'Foot',
      plural: 'Feet'
    }
  }
};

export enum UnitType {
  /**
   * cm or in
   */
  LEVEL_SM = 'LEVEL_SM',
  /**
   * metre or foot.
   * We don't name it as LEVEL_M as M could be mistaken for 'metre'.
   */
  LEVEL_MD = 'LEVEL_MD',
  /** flow per minute */
  FLOW = 'FLOW',
  /** flow per hour */
  FLOW_HOURLY = 'FLOW_HOURLY',
  /** litre or gallon */
  VOLUME_SM = 'VOLUME_SM',
  /** kilolitre or cubic-foot */
  VOLUME_MD = 'VOLUME_MD',
  /**
   * megalitre or acre-foot.
   * We don't name it as VOLUME_L as L could be mistaken for 'litre'.
   */
  VOLUME_LG = 'VOLUME_LG',
  /** cm/h or in/h */
  TREND_SM = 'TREND_SM',
  /** metre/h or ft/h */
  TREND_MD = 'TREND_MD',
  RAIN = 'RAIN',
  SIZE = 'SIZE',
  HUMIDITY = 'HUMIDITY',
  /** hPa or inHg */
  PRESSURE_MD = 'PRESSURE_MD',
  /** kPa or psi */
  PRESSURE_LG = 'PRESSURE_LG',
  AREA_SM = 'AREA_SM',
  MATTER = 'MATTER',
  TEMPERATURE = 'TEMPERATURE',
  SOLAR_RADIATION = 'SOLAR_RADIATION',
  WIND_SPEED = 'WIND_SPEED',
  VOLTAGE = 'VOLTAGE'
}

interface GetUnitByCountryParams {
  unitType: UnitType;
  country: string;
}

type UnitFlowMinute = 'L/min' | 'gal/min';
type UnitFlowHour = 'L/hr' | 'gal/hr';
type UnitTrendMd = 'm/h' | 'ft/h';
type UnitTrendSm = 'cm/h' | 'in/h';
export type UnitAreaSm = 'm²' | 'ft²';
export type UnitElectricCurrent = 'A';
export type UnitEnergyFlux = 'W/m²';
export type UnitEnergyUsage = 'kWh';
export type UnitLevelMd = LevelDisplayUnit.M | LevelDisplayUnit.FT;
export type UnitLevelSm = LevelDisplayUnit.CM | LevelDisplayUnit.IN;
export type UnitMatter = 'kg/ha' | 'lb/ac';
export type UnitPressureMd = 'hPa' | 'inHg';
export type UnitPressureLg = 'kPa' | 'psi';
export type UnitRain = 'mm' | 'in';
export type UnitSize = 'ha' | 'ac';
export type UnitTemperature = '°C' | '°F';
export type UnitVoltage = 'V';
export type UnitVolumeLg = VolumeDisplayUnit.ML | VolumeDisplayUnit.AC_FT;
export type UnitVolumeMd = VolumeDisplayUnit.KL | 'ft³';
export type UnitVolumeSm = VolumeDisplayUnit.L | VolumeDisplayUnit.GAL;
export type UnitWindSpeed = 'km/h' | 'mph';

export type UnitSymbol =
  | UnitAreaSm
  | UnitElectricCurrent
  | UnitEnergyFlux
  | UnitEnergyUsage
  | UnitFlowHour
  | UnitFlowMinute
  | UnitLevelMd
  | UnitLevelSm
  | UnitMatter
  | UnitPressureLg
  | UnitPressureMd
  | UnitRain
  | UnitSize
  | UnitTemperature
  | UnitTrendMd
  | UnitTrendSm
  | UnitVoltage
  | UnitVolumeLg
  | UnitVolumeMd
  | UnitVolumeSm
  | UnitWindSpeed
  | '%';

/**
 * If getting unit in a React component, please use useGetLocaleUnit instead.
 * @returns Unit symbol
 */
export function getUnitSymbolByCountry<S extends UnitSymbol>({
  unitType,
  country
}: GetUnitByCountryParams): S {
  const isUS = country === 'USA';
  // @uom-refactor to return a unit object which contains
  // both short & long name and symbol etc. And the unit can be used as a
  // type-safe parameter to construct a quantity object.
  switch (unitType) {
    case UnitType.LEVEL_SM:
      return (isUS ? 'in' : 'cm') as S;
    case UnitType.LEVEL_MD:
      return (isUS ? 'ft' : 'm') as S;
    case UnitType.FLOW:
      return (isUS ? 'gal/min' : 'L/min') as S;
    case UnitType.FLOW_HOURLY:
      return (isUS ? 'gal/hr' : 'L/hr') as S;
    case UnitType.VOLUME_SM:
      return (isUS ? 'gal' : 'L') as S;
    case UnitType.VOLUME_MD:
      return (isUS ? 'ft³' : 'KL') as S;
    case UnitType.VOLUME_LG:
      return (isUS ? 'ac. ft.' : 'ML') as S;
    case UnitType.TREND_SM:
      return (isUS ? 'in/h' : 'cm/h') as S;
    case UnitType.TREND_MD:
      return (isUS ? 'ft/h' : 'm/h') as S;
    case UnitType.RAIN:
      return (isUS ? 'in' : 'mm') as S;
    case UnitType.SIZE:
      return (isUS ? 'ac' : 'ha') as S;
    case UnitType.PRESSURE_MD:
      return (isUS ? 'inHg' : 'hPa') as S;
    case UnitType.PRESSURE_LG:
      return (isUS ? 'psi' : 'kPa') as S;
    case UnitType.AREA_SM:
      return (isUS ? 'ft²' : 'm²') as S;
    case UnitType.MATTER:
      return (isUS ? 'lb/ac' : 'kg/ha') as S;
    case UnitType.TEMPERATURE:
      return (isUS ? '°F' : '°C') as S;
    case UnitType.WIND_SPEED:
      return (isUS ? 'mph' : 'km/h') as S;
    case UnitType.SOLAR_RADIATION:
      return 'W/m²' as S;
    case UnitType.VOLTAGE:
      return 'V' as S;
    case UnitType.HUMIDITY:
      return '%' as S;
    default:
      return 'cm' as S;
  }
}

export function useGetLocaleUnitSymbol<S extends UnitSymbol>(unitType: UnitType): S {
  const country = useEnterpriseCountry();
  return getUnitSymbolByCountry<S>({ unitType, country });
}
