import {
  DETECTED_BIT_AND_SAMPLE_RECEIVED_MISMATCH,
  DETECTED_BIT_AND_SAMPLE_RECEIVED_MISMATCH_DISPLAY,
  LINE_MISSING_DISPLAY,
  LINE_MISSING_INDICATOR
} from 'constants/power-meter';
import { LineValue } from 'types/models/power-meter';
import formatQuantity from 'utils/format-metric';

// Allowing the flexibility of undefined input because formatQuantity can handle it
export const formatPowerMeterLineVoltage = (lineValue: LineValue | undefined) => {
  if (lineValue === LINE_MISSING_INDICATOR) return LINE_MISSING_DISPLAY;
  if (lineValue === DETECTED_BIT_AND_SAMPLE_RECEIVED_MISMATCH) return DETECTED_BIT_AND_SAMPLE_RECEIVED_MISMATCH_DISPLAY;
  return formatQuantity(lineValue, { suffix: 'V' });
};

// Allowing the flexibility of undefined input because formatQuantity can handle it
export const formatPowerMeterLineCurrent = (lineValue: LineValue | undefined) => {
  if (lineValue === LINE_MISSING_INDICATOR) return LINE_MISSING_DISPLAY;
  if (lineValue === DETECTED_BIT_AND_SAMPLE_RECEIVED_MISMATCH) return DETECTED_BIT_AND_SAMPLE_RECEIVED_MISMATCH_DISPLAY;
  return formatQuantity(lineValue, { suffix: 'A' });
};