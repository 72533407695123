import React, { memo } from 'react';

import FarmbotFlowIcon from 'components/icons/FarmbotFlowIcon';
import FarmbotGridIcon from 'components/icons/FarmbotGridIcon';
import FarmbotTroughIcon from 'components/icons/FarmbotTroughIcon';
import FarmbotWaterTankIcon from 'components/icons/FarmbotWaterTankIcon';
import { AssetTypeCode, AssetTypeId } from 'types/models/asset-type';
import { isWeatherAssetType } from 'types/weather.enum';

import FarmbotBoreIcon from '../FarmbotBoreIcon';
import FarmbotCameraIcon from '../FarmbotCameraIcon';
import FarmbotCloudRainIcon from '../FarmbotCloudRainIcon';
import FarmbotDamIcon from '../FarmbotDamIcon';
import FarmbotFuelTankIcon from '../FarmbotFuelTankIcon';
import FarmbotLinePressureIcon from '../FarmbotLinePressureIcon';
import FarmbotLiquidFertiliserIcon from '../FarmbotLiquidFertiliserIcon';
import FarmbotMachineControlIcon from '../FarmbotMachineControlIcon';
import FarmbotPowerMeterIcon from '../FarmbotPowerMeterIcon';
import FarmbotSafetyCheckInIcon from '../FarmbotSafetyCheckInIcon';
import FarmbotSecurityCameraIcon from '../FarmbotSecurityCameraIcon';
import FarmbotSoilMoistureIcon from '../FarmbotSoilMoistureIcon';
import WeatherStationIcon from '../WeatherStationIcon';

interface Props {
  assetTypeId?: AssetTypeId;
}

function AssetTypeIcon({ assetTypeId }: Props): JSX.Element | null {
  if (assetTypeId === AssetTypeCode.WEATHER_STATION_CONTROLLER
    || (assetTypeId && isWeatherAssetType(assetTypeId))) {
    return <WeatherStationIcon />;
  }
  switch (assetTypeId) {
    case AssetTypeCode.WATER_TANK:
      return <FarmbotWaterTankIcon />;
    case AssetTypeCode.PIPE:
      return <FarmbotFlowIcon />;
    case AssetTypeCode.TROUGH:
      return <FarmbotTroughIcon />;
    case AssetTypeCode.RAIN_GAUGE:
      return <FarmbotCloudRainIcon />;
    case AssetTypeCode.SOIL:
      return <FarmbotSoilMoistureIcon />;
    case AssetTypeCode.FUEL_TANK:
      return <FarmbotFuelTankIcon />;
    case AssetTypeCode.LIQUID_FERTILISER_TANK:
      return <FarmbotLiquidFertiliserIcon />;
    case AssetTypeCode.LINE_PRESSURE:
      return <FarmbotLinePressureIcon />;
    case AssetTypeCode.DAM:
      return <FarmbotDamIcon />;
    case AssetTypeCode.CAMERA:
      return <FarmbotCameraIcon />;
    case AssetTypeCode.BORE:
      return <FarmbotBoreIcon />;
    case AssetTypeCode.SAFETY_CHECK_IN:
      return <FarmbotSafetyCheckInIcon />;
    case AssetTypeCode.MACHINE_CONTROL:
      return <FarmbotMachineControlIcon />;
    case AssetTypeCode.SECURITY_CAMERA:
      return <FarmbotSecurityCameraIcon />;
    case AssetTypeCode.POWER_METER:
      return <FarmbotPowerMeterIcon />;
    default:
      return <FarmbotGridIcon />;
  }
}

export default memo(AssetTypeIcon);
