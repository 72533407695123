import { useSelector } from 'react-redux';

import { selectCurrentEnterpriseCountry } from 'store/modules/enterprise/selectors';

// TODO: Introduce the real react-i18next library to enjoy the nesting keys,
// nesting translations, plurals, etc.
const au = {
  BORE_HIGH_ALERT_HELP_TEXT: 'A warning alert will be sent when the bore water level is above this threshold.',
  BORE_HIGH_WARNING_ALERT: 'Bore Level High (Warning)',
  BORE_LOW_ALERT_HELP_TEXT: 'A warning alert will be sent when the bore water level is below this threshold.',
  BORE_LOW_WARNING_ALERT: 'Bore Level Low (Warning)',
  CRITICAL: 'Critical',
  DAM: 'Dam',
  DAM_BOTTOM_UP_MEASUREMENT: 'calculates the water level from the floor of the dam up to the current water level.',
  DAM_HIGH_ALERT_HELP_TEXT: 'A {{level}} alert will be sent when the dam fills to this level or above.',
  DAM_HIGH_CRITICAL_ALERT: 'Dam High - Red Alert (Critical)',
  DAM_HIGH_WARNING_ALERT: 'Dam High - Amber Alert (Warning)',
  DAM_LOW_ALERT_HELP_TEXT: 'A {{level}} alert will be sent when the dam is below this water level.',
  DAM_LOW_CRITICAL_ALERT: 'Dam Low - Red Alert (Critical)',
  DAM_LOW_WARNING_ALERT: 'Dam Low - Amber Alert (Warning)',
  DAM_SURVEY_FILLING_INSTRUCTION_ONE: 'Enter incremental volume in megalitres (ML) and reduced levels in metres (m).',
  DAM_SURVEY_FILLING_INSTRUCTION_TWO: 'It is optional to add the surface area in m² (sq. m).',
  DAM_TOP_DOWN_MEASUREMENT: 'calculates the water level from the top of the dam down to the current water level.',
  FARMBOT: 'Farmbot',
  HELP_CENTRE: 'Help Centre',
  LIQUID_FERTILISER: 'Liquid Fertiliser',
  LIQUID_FERTILISER_CRITICAL_ALERT_HELP_TEXT: 'A critical alert will be sent when the liquid fertiliser is below this level.',
  LIQUID_FERTILISER_WARNING_ALERT_HELP_TEXT: 'A warning alert will be sent when the liquid fertiliser is below this level.',
  LOCALISATION: 'Localisation',
  PADDOCK: 'Paddock',
  PIPE_VOLUME_HIGH_ALERT_HELP_TEXT: 'A warning alert will be sent when the aggregate volume within the rolling window is greater than or equal to the value entered. E.g. 50,000 litres or more pass through in the last 12 hours',
  PIPE_VOLUME_LOW_ALERT_HELP_TEXT: 'A warning alert will be sent when the aggregate volume within the rolling window is less than or equal to the value entered. E.g. 100 litres or less pass through in the last 6 hours',
  RAIN_HIGH_ALERT_HELP_TEXT: 'A warning alert will be sent when the total rainfall within the time period is greater than or equal to the value entered, e.g. 50 mm or more in the last 6 hours.',
  REDUCED_LEVEL: 'Reduced Level',
  RL: 'RL',
  SQUARE_METERS: 'm²',
  TANK_LEVEL_EXCESSIVE_RATE_OF_FALL_HELP_TEXT: 'Send a critical alert when the {{assetTypeName}}\'s level has fallen significantly in a relatively short period. E.g., fallen by {{fallAmount}} {{fallUnit}} in {{timePeriod}}.',
  WARNING: 'Warning'
};

const us = {
  BORE_HIGH_ALERT_HELP_TEXT: 'A warning alert will be sent when the well water level is above this threshold.',
  BORE_HIGH_WARNING_ALERT: 'Well Level High (Warning)',
  BORE_LOW_ALERT_HELP_TEXT: 'A warning alert will be sent when the well water level is below this threshold.',
  BORE_LOW_WARNING_ALERT: 'Well Level Low (Warning)',
  CRITICAL: 'Critical',
  DAM: 'Reservoir',
  DAM_BOTTOM_UP_MEASUREMENT: 'calculates the water level from the floor of the reservoir up to the current water level.',
  DAM_HIGH_ALERT_HELP_TEXT: 'A {{level}} alert will be sent when the reservoir fills to this level or above.',
  DAM_HIGH_CRITICAL_ALERT: 'Reservoir High (Critical)',
  DAM_HIGH_WARNING_ALERT: 'Reservoir High (Warning)',
  DAM_LOW_ALERT_HELP_TEXT: 'A {{level}} alert will be sent when the reservoir is below this water level.',
  DAM_LOW_CRITICAL_ALERT: 'Reservoir Low (Critical)',
  DAM_LOW_WARNING_ALERT: 'Reservoir Low (Warning)',
  DAM_SURVEY_FILLING_INSTRUCTION_ONE: 'Enter incremental volume in cubic feet (cu. ft.) and elevation in feet (ft).',
  DAM_SURVEY_FILLING_INSTRUCTION_TWO: 'It is optional to add the surface area in ft² (sq. ft.).',
  DAM_TOP_DOWN_MEASUREMENT: 'calculates the water level from the top of the reservoir down to the current water level. The measurement may be referred to as the freeboard level.',
  FARMBOT: 'Ranchbot',
  HELP_CENTRE: 'Help Center',
  LIQUID_FERTILISER: 'Liquid Fertilizer',
  LIQUID_FERTILISER_CRITICAL_ALERT_HELP_TEXT: 'A critical alert will be sent when the liquid fertilizer is below this level.',
  LIQUID_FERTILISER_WARNING_ALERT_HELP_TEXT: 'A warning alert will be sent when the liquid fertilizer is below this level.',
  LOCALISATION: 'Localization',
  PADDOCK: 'Field',
  PIPE_VOLUME_HIGH_ALERT_HELP_TEXT: 'A warning alert will be sent when the aggregate volume within the rolling window is greater than or equal to the value entered. E.g. 50,000 gallons or more pass through in the last 12 hours',
  PIPE_VOLUME_LOW_ALERT_HELP_TEXT: 'A warning alert will be sent when the aggregate volume within the rolling window is less than or equal to the value entered. E.g. 100 gallons or less pass through in the last 6 hours',
  RAIN_HIGH_ALERT_HELP_TEXT: 'A warning alert will be sent when the total rainfall within the time period is greater than or equal to the value entered, e.g. 2 inches or more in the last 6 hours.',
  REDUCED_LEVEL: 'Elevation',
  RL: 'Elev.',
  SQUARE_METERS: 'ft²',
  TANK_LEVEL_EXCESSIVE_RATE_OF_FALL_HELP_TEXT: 'Send a critical alert when the {{assetTypeName}}\'s level has fallen significantly in a relatively short period. E.g., fallen by {{fallAmount}} {{fallUnit}} in {{timePeriod}}.',
  WARNING: 'Warning'
};

/** A fake react-i18next hook */
const useTranslation = () => {
  const country = useSelector(selectCurrentEnterpriseCountry);
  const translations = ['USA', 'MEX', 'PRY'].includes(country) ? us : au;
  return {
    t: (key: keyof typeof au, fillings?: Record<string, string>) => {
      if (fillings) {
        // ref https://www.i18next.com/translation-function/interpolation
        return (translations[key] || key).replace(
          /{{(\w+)}}/g,
          (_, p1: string) => fillings[p1]
        );
      }
      return translations[key] || key;
    }
  };
};

export default useTranslation;
