import { AxiosError, AxiosResponse } from 'axios';
import { action } from 'typesafe-actions';

import { PowerMeterSample } from 'types/models/power-meter';
import Sample, { ControlPointSample, SampleDate, SampleId } from 'types/models/sample';
import SamplePoint, { SamplePointId } from 'types/models/samplePoint';

import ActionTypes from './constants';
import { ConnectedSamplePointIds, SampleInterval } from './types';

/**
 * Except when loading samples for Power Meter, this will write to the store at the key `samplePointSamples`
 * so it will overwrite any existing samples there.
 */
export const loadSamplePointSamples = (
  samplePointId: SamplePointId,
  startDateMs: number,
  endDateMs: number,
  withErrorSamples?: boolean,
  onSuccess?: () => void
) =>
  action(ActionTypes.LOAD_SAMPLE_POINT_SAMPLES_REQUEST, {
    samplePointId,
    startDateMs,
    endDateMs,
    withErrorSamples,
    onSuccess
  });

export const loadSamplePointSamplesSuccess = (
  response: AxiosResponse | AxiosResponse[]
) => action(ActionTypes.LOAD_SAMPLE_POINT_SAMPLES_SUCCESS, { response });

export const loadSamplePointSamplesFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_SAMPLE_POINT_SAMPLES_FAILURE,
    { message, error },
    undefined,
    true
  );

/**
 * This is different from `loadSamplePointSamples` in that the samples are allocated into intervals,
 * which could be hourly, daily, etc. (see the `interval` parameter for the supported intervals).
 * This will write to the store at the key `samplePointSamples` so it will overwrite any existing samples there.
 */
export const loadIntervalSamples = (
  /** Used in API call */
  samplePointSid: SamplePoint['sid'],
  /** Used to enrich each sample record before putting it into the store */
  samplePointId: SamplePointId,
  /** Used for metric-to-imperial conversions, if any */
  assetTypeId: SamplePoint['assetTypeId'],
  /** In milliseconds */
  startDate: number,
  /** In milliseconds */
  endDate: number,
  interval: SampleInterval,
  siteTimezoneCode: string,
  dailyResetTime: number
) =>
  action(ActionTypes.LOAD_INTERVAL_SAMPLES_REQUEST, {
    samplePointSid,
    samplePointId,
    assetTypeId,
    startDate,
    endDate,
    interval,
    siteTimezoneCode,
    dailyResetTime
  });

export const loadIntervalSamplesSuccess = (
  response: AxiosResponse | AxiosResponse[]
) => action(ActionTypes.LOAD_INTERVAL_SAMPLES_SUCCESS, { response });

export const loadIntervalSamplesFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_INTERVAL_SAMPLES_FAILURE,
    { message, error },
    undefined,
    true
  );

export const loadMachineControlSamples = (
  machineControlId: SamplePointId,
  startDateMs: number,
  endDateMs: number,
  siteTimezoneCode: string,
  connectedSamplePointIds: ConnectedSamplePointIds
) =>
  action(ActionTypes.LOAD_MACHINE_CONTROL_SAMPLES_REQUEST, {
    machineControlId,
    startDateMs,
    endDateMs,
    siteTimezoneCode,
    connectedSamplePointIds
  });

export const loadMachineControlSamplesSuccess = (samples: Sample[]) =>
  action(ActionTypes.LOAD_MACHINE_CONTROL_SAMPLES_SUCCESS, { samples });

export const loadMachineControlSamplesFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_MACHINE_CONTROL_SAMPLES_FAILURE,
    { message, error },
    undefined,
    true
  );

export const loadInternalSamples = (
  samplePointId: SamplePointId,
  startDateMs: number,
  endDateMs: number,
  siteTimezoneCode: string
) =>
  action(ActionTypes.LOAD_INTERNAL_SAMPLES_REQUEST, {
    samplePointId,
    startDateMs,
    endDateMs,
    siteTimezoneCode
  });

export const loadInternalSamplesSuccess = (response: AxiosResponse) =>
  action(ActionTypes.LOAD_INTERNAL_SAMPLES_SUCCESS, { response });

export const loadInternalSamplesFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_INTERNAL_SAMPLES_FAILURE,
    { message, error },
    undefined,
    true
  );

export const loadComparativeSamples = ({ dateRanges, samplePointSids }: {
  dateRanges: { start: number, end: number, interval: SampleInterval, timezoneCode: string }[],
  samplePointSids: SamplePoint['sid'][]
}) =>
  action(ActionTypes.LOAD_COMPARATIVE_SAMPLES_REQUEST, { dateRanges, samplePointSids });

export const loadComparativeSamplesSuccess = (
  response: AxiosResponse | AxiosResponse[]
) => action(ActionTypes.LOAD_COMPARATIVE_SAMPLES_SUCCESS, { response });

export const loadComparativeSamplesFailure = (
  message: string,
  error?: AxiosError
) =>
  action(
    ActionTypes.LOAD_COMPARATIVE_SAMPLES_FAILURE,
    { message, error },
    undefined,
    true
  );

export const setSamplePointSamples = (samples: Record<SampleId, Sample>) =>
  action(ActionTypes.SET_SAMPLE_POINT_SAMPLES, { samples });

export const setControlPointSamples = (
  samples: Record<SampleId, ControlPointSample>
) => action(ActionTypes.SET_CONTROL_POINT_SAMPLES, samples);

export const setPowerMeterSamples = (samples: Record<SampleDate, PowerMeterSample>) =>
  action(ActionTypes.SET_POWER_METER_SAMPLES, samples);