/* eslint-disable no-underscore-dangle */

import mapKeys from 'lodash/mapKeys';

import { AssetTypeCode, RawAssetTypeCode } from 'types/models/asset-type';
import SamplePoint, {
  RawSamplePoint,
  SamplePointLastSampleData
} from 'types/models/samplePoint';
import SamplePointStatistic, {
  SamplePointStatisticWithAggregates
} from 'types/models/samplePointsStatistic';
import parseRawSamplePoint from 'utils/associated-sample-points/parse-raw-samplepoint';
import dateToISOString from 'utils/date-to-iso-string';

/**
 * Merge associated soil moisture & temperature samples into a single sample.
 */
export const mergeSoilMoistureAndTempSamples = (
  soilMoistureSample: SamplePointLastSampleData,
  soilTempSample: SamplePointLastSampleData
): SamplePointLastSampleData =>
  ({
    ...soilMoistureSample,
    multiDimValues: {
      // Give each value a different dataType so we can tell them apart.
      sampleDim: [
        {
          ...soilMoistureSample,
          dataType: RawAssetTypeCode.SOIL_MOISTURE
        },
        {
          ...soilTempSample,
          dataType: RawAssetTypeCode.SOIL_TEMP
        }
      ].map(({ rwValue, dataType, date, prevDate }) => ({
        rwValue,
        dataType,
        sampleDate: dateToISOString(date),
        prevSampleDate: dateToISOString(prevDate)
      }))
    },
    // Keep original data of temp sample, so we can recreate it if needed.
    _hidden: soilTempSample
  }) as SamplePointLastSampleData;

/**
 * Takes a raw soil samplePoint, and a parsed soil samplePoint, and merges them
 * into a single parsed samplePoint.
 */
const mergeSoilSamplePoints = (
  rawSoilSamplePoint: RawSamplePoint,
  soilSamplePoint: SamplePoint
): SamplePoint => {
  const rawSamplePointIsMoisture =
    rawSoilSamplePoint.assetTypeId === RawAssetTypeCode.SOIL_MOISTURE;

  const [moisture, temp] = rawSamplePointIsMoisture
    ? [parseRawSamplePoint(rawSoilSamplePoint) as SamplePoint, soilSamplePoint]
    : [soilSamplePoint, parseRawSamplePoint(rawSoilSamplePoint) as SamplePoint];
  return {
    ...moisture,
    lastSampleData:
      moisture.lastSampleData && temp.lastSampleData
        ? mergeSoilMoistureAndTempSamples(
          moisture.lastSampleData,
          temp.lastSampleData
        )
        : undefined,
    // Keep original data of temp samplepoint, so we can recreate it if needed.
    _hidden: temp
  } as SamplePoint;
};

/**
 * Merge associated soil moisture & temperature samplePointStatistics into a
 * single samplePointStatistic.
 */
export const mergeSoilSamplePointStatistics = (
  moisture: SamplePointStatisticWithAggregates,
  temp: SamplePointStatisticWithAggregates
) =>
  ({
    ...moisture,
    lastSample:
      moisture.lastSample && temp.lastSample
        ? mergeSoilMoistureAndTempSamples(
          moisture.lastSample as unknown as SamplePointLastSampleData,
          temp.lastSample as unknown as SamplePointLastSampleData
        )
        : moisture.lastSample,
    aggregates: Object.entries(moisture.aggregates).reduce(
      (acc, [key, value]) => {
        const moistureStats: Record<string, number | null> = mapKeys(value.values, (_, k) => `${k}Moisture`);
        const tempStats: Record<string, number | null> = temp.aggregates ? mapKeys(temp.aggregates[key].values, (_, k) => `${k}Temp`) : {};

        return {
          ...acc,
          [key]: {
            name: value.name,
            values: {
              ...moistureStats,
              ...tempStats
            }
          }
        };
      },
      {}
    ),
    _hidden: temp
  }) as SamplePointStatistic;

/**
 * Returns true if the given raw & parsed soil samplePoints are associated.
 * That is, they have the same serial number (and are both soil samplePoints).
 */
const isMatchingSoilSamplePoint = (
  soilSamplePoint: RawSamplePoint,
  samplePoint: SamplePoint
) =>
  (soilSamplePoint.assetTypeId === RawAssetTypeCode.SOIL_MOISTURE ||
    soilSamplePoint.assetTypeId === RawAssetTypeCode.SOIL_TEMP) &&
  samplePoint.assetTypeId === AssetTypeCode.SOIL &&
  soilSamplePoint.deviceTags?.serialNumber ===
  samplePoint.deviceTags?.serialNumber;

/**
 * Takes an array of parsed samplePoints and a raw samplePoint, and
 * returns an array of parsed samplePoints.
 */
export const soilSamplePointReducer = (
  accumulatedSamplePoints: SamplePoint[],
  currentSoil: RawSamplePoint
): SamplePoint[] => {
  const nextSamplePoints: SamplePoint[] = [];
  let merged = false;

  // Scan through the accumulated parsed sample points, if currentSoil
  // pairs with an accumulated sample point, merge them. Otherwise,
  // keep copying SP from accumulatedSamplePoints to nextSamplePoints array.
  for (let i = 0; i < accumulatedSamplePoints.length; i++) {
    const samplePoint = accumulatedSamplePoints[i];

    if (isMatchingSoilSamplePoint(currentSoil, samplePoint)) {
      nextSamplePoints.push(
        mergeSoilSamplePoints(currentSoil, samplePoint)
      );
      merged = true;
    } else {
      nextSamplePoints.push(samplePoint);
    }
  }
  // If currentSoil hasn't paired with any SP, parse it then add to the parsed sample points.
  if (!merged) {
    const parsedSoil = parseRawSamplePoint(currentSoil);
    if (parsedSoil) {
      nextSamplePoints.push(parsedSoil);
    }
  }

  return nextSamplePoints;
};
