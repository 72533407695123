import { type Nibble } from './bit';

type CurrentOrVoltageLinePresence = {
  isExpectedPresent: boolean;
  isActuallyPresent: boolean;
};

export const checkLinePresence = (nibble: Nibble): CurrentOrVoltageLinePresence => {
  const isExpectedPresent = nibble[0] === '1';
  const isActuallyPresent = nibble[1] === '1';
  return { isExpectedPresent, isActuallyPresent };
};