export const blue = '#307fbe';
export const coolGrey = '#e1e3e5';
export const darkBlue = '#054b7a';
export const darkCoolGrey = '#263238';
export const darkGreen = '#0f873d';
export const darkOrange = '#c9943e';
export const darkPink = '#d30d8d';
export const darkPurple = '#50287f';
export const darkRed = '#b52828';
export const disabledGrey = '#b0bec5';
export const green = '#27ae60';
export const grey = '#f2f2f2';
export const lightBlue = '#6eb4d6';
export const lightCoolGrey = '#e6eaed';
export const lightGreen = '#57bc85';
export const lightGrey = '#fafafa';
export const lightYellow = '#f7df9a';
export const lighterBlue = '#a3e4f7';
export const lighterGreen = '#7ce0a7';
export const lighterOrange = '#f3ebc7';
export const lighterPurple = '#f5cdeb';
export const midCoolGrey = '#b3bec4';
export const midCoolGrey2 = '#4f636e';
export const orange = '#e0a53c';
export const pink = '#f40bac';
export const purple = '#7242ce';
export const red = '#db4439';
export const skyBlue = '#64cced';
export const white = '#fff';
export const yellow = '#f2c94c';