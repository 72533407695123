import { RawAssetTypeCode } from 'types/models/asset-type';
import SamplePoint, { RawSamplePoint } from 'types/models/samplePoint';
import { powerMeterSamplePointReducer } from 'utils/associated-sample-points/merge-power-meters';
import { safetyCheckInSamplePointReducer } from 'utils/associated-sample-points/merge-safety-check-in-sos';
import { soilSamplePointReducer } from 'utils/associated-sample-points/merge-soil-moisture-temperature';
import parseRawSamplePoint from 'utils/associated-sample-points/parse-raw-samplepoint';

import { machineControlSamplePointReducer } from '../merge-machine-controls';
import { weatherStationSamplePointReducer } from '../merge-weather-station-sample-points';

/**
 * Takes an array of raw samplePoints and returns an array of parsed
 * samplePoints. SamplePoints of unsupported asset types are removed.
 */
export const parseRawSamplePoints = (
  rawSamplePoints: RawSamplePoint[],
  country: string
): SamplePoint[] => {
  // To make sure the main sample points, during merging, are always in front of other sample points, we sort the array
  // first. It simplifies the merging logic.
  const sortedRawSamplePoints = rawSamplePoints
    .sort((a, b) => {
      // If a is a weather station controller, it should be in front of b.
      if (a.assetTypeId === RawAssetTypeCode.WEATHER_STATION_CONTROLLER) return -1;
      return 1;
    });

  return sortedRawSamplePoints
    .reduce<SamplePoint[]>((acc, rawSP) => {
      switch (rawSP.assetTypeId) {
        case RawAssetTypeCode.SOIL_MOISTURE:
        case RawAssetTypeCode.SOIL_TEMP:
          return soilSamplePointReducer(acc, rawSP);
        case RawAssetTypeCode.SAFETY_CHECK_IN_SOS:
        case RawAssetTypeCode.SAFETY_CHECK_IN_VISIT:
          return safetyCheckInSamplePointReducer(acc, rawSP);
        case RawAssetTypeCode.MACHINE_CONTROL:
          return machineControlSamplePointReducer(acc, rawSP);
        case RawAssetTypeCode.POWER_METER_FLAGS:
        case RawAssetTypeCode.POWER_METER_VOLTAGE:
        case RawAssetTypeCode.POWER_METER_CURRENT:
        case RawAssetTypeCode.POWER_METER_ENERGY:
          return powerMeterSamplePointReducer(acc, rawSP);
        case RawAssetTypeCode.BAROMETRIC_PRESSURE:
        case RawAssetTypeCode.DEW_POINT:
        case RawAssetTypeCode.WEATHER_STATION_BATTERY:
        case RawAssetTypeCode.WEATHER_STATION_CONTROLLER:
        case RawAssetTypeCode.HUMIDITY:
        case RawAssetTypeCode.RAIN_GAUGE:
        case RawAssetTypeCode.SOLAR_RADIATION:
        case RawAssetTypeCode.TEMPERATURE:
        case RawAssetTypeCode.WIND:
          return weatherStationSamplePointReducer(acc, rawSP);
        default: {
          const parsedSP = parseRawSamplePoint(rawSP, country);
          if (!parsedSP) return acc;
          return [...acc, parsedSP];
        }
      }
    }, []);
};
