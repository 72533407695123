import AuthActionTypes from 'store/modules/auth/constants';
import { ApplicationActions } from 'store/types';
import Enterprise from 'types/models/enterprise';

import ActionTypes from './constants';
import { SearchState } from './types';

export const initialState: SearchState = {};

function searchReducer(
  state: SearchState = initialState,
  action: ApplicationActions
): SearchState {
  switch (action.type) {
    case ActionTypes.SEARCH_SUCCESS: {
      const { payload } = action;
      const { searchType, searchResult } = payload;
      return {
        ...state,
        // TODO: - will need to change this once we support searching other data.
        [searchType]: searchResult as Enterprise[]
      };
    }
    case AuthActionTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default searchReducer;
