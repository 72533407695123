import round from 'lodash/round';

import { CIBO_AREA_PRECISION, CIBO_MATTER_PRECISION } from 'constants/integrations';
import { CiboLocationRawStatus, CiboLocationStatus } from 'store/modules/ciboLocations/types';
import convertUnit, { Quantity } from 'utils/convert-unit';
import { UnitMatter, UnitType, getUnitSymbolByCountry } from 'utils/get-unit-by-country';

type ParseCiboLocationRawStatusType = (rawStatus: CiboLocationRawStatus, country: string) => CiboLocationStatus;

export const parseCiboLocationRawStatus: ParseCiboLocationRawStatusType = (
  {
    tsdmKgha,
    greenMatterKgha,
    dryMatterKgha,
    areaHa,
    groundCoverPercent
  },
  country
) => {
  const isUS = country === 'USA';
  const tsdm = new Quantity(tsdmKgha, {
    symbol: 'kg/ha'
  });
  const greenMatter = new Quantity(greenMatterKgha, {
    symbol: 'kg/ha'
  });
  const dryMatter = new Quantity(dryMatterKgha, {
    symbol: 'kg/ha'
  });
  const area = new Quantity(round(areaHa, CIBO_AREA_PRECISION), {
    symbol: 'ha'
  });

  return {
    tsdm,
    tsdmLocale: new Quantity(
      isUS ? convertUnit(tsdm, { symbol: 'lb/ac' }, CIBO_MATTER_PRECISION) : tsdmKgha,
      {
        symbol: getUnitSymbolByCountry({ unitType: UnitType.MATTER, country }) as UnitMatter
      }),
    greenMatter,
    greenMatterLocale: new Quantity(
      isUS ? convertUnit(greenMatter, { symbol: 'lb/ac' }, CIBO_MATTER_PRECISION) : greenMatterKgha,
      {
        symbol: getUnitSymbolByCountry({ unitType: UnitType.MATTER, country }) as UnitMatter
      }),
    dryMatter,
    dryMatterLocale: new Quantity(
      isUS ? convertUnit(dryMatter, { symbol: 'lb/ac' }, CIBO_MATTER_PRECISION) : dryMatterKgha,
      {
        symbol: getUnitSymbolByCountry({ unitType: UnitType.MATTER, country }) as UnitMatter
      }),
    area,
    areaLocale: new Quantity(
      isUS ? convertUnit(area, { symbol: 'ac' }, CIBO_AREA_PRECISION) : area.value,
      {
        symbol: getUnitSymbolByCountry({ unitType: UnitType.SIZE, country }) as UnitMatter
      }),
    groundcover: new Quantity(groundCoverPercent, {
      symbol: '%'
    })
  };
};