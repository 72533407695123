import isNil from 'lodash/isNil';

import { DETECTED_BIT_AND_SAMPLE_RECEIVED_MISMATCH } from 'constants/power-meter';
import { LineValue, PowerLine, PowerMeterStatus } from 'types/models/power-meter';
import Sample from 'types/models/sample';
import SamplePoint from 'types/models/samplePoint';
import { PowerMeterStatistic } from 'types/models/samplePointsStatistic';
import { trend } from 'utils/Math/trend';
import {
  DetectedBitAndSampleReceivedMismatchError,
  checkSupportedLinesAgainstFlags,
  getCurrentValueAgainstFlags,
  getVoltageValueAgainstFlags
} from 'utils/Sample/power-meter';

// ==============================
// TYPES
// ==============================
type LinesSummary = {
  line1: number | null;
  line2: number | null;
  line3: number | null;
};

type CalculateCurrentOrVoltageSummaryFunction = (
  valueType: 'current' | 'voltage',
  lines: {
    line1Statistic: PowerMeterStatistic['line1Current'] | PowerMeterStatistic['line1Voltage'];
    line2Statistic: PowerMeterStatistic['line2Current'] | PowerMeterStatistic['line2Voltage'];
    line3Statistic: PowerMeterStatistic['line3Current'] | PowerMeterStatistic['line3Voltage'];
  },
  metadata: {
    flagsSampleValue: Sample['rwValue'] | undefined
  }
) => LinesSummary;

// ==============================
// HELPERS
// ==============================
const getValueAndHandleError = (
  valueType: 'current' | 'voltage',
  flagsSampleValue: number,
  lineNumber: PowerLine['lineNumber'],
  lineStatistic:
    | PowerMeterStatistic['line1Current']
    | PowerMeterStatistic['line1Voltage']
    | PowerMeterStatistic['line2Current']
    | PowerMeterStatistic['line2Voltage']
    | PowerMeterStatistic['line3Current']
    | PowerMeterStatistic['line3Voltage']
): LineValue => {
  let lineValue: LineValue = null;
  const checkValueAgainstFlags = valueType === 'current'
    ? getCurrentValueAgainstFlags
    : getVoltageValueAgainstFlags;
  const lineSampleValue: number | undefined = lineStatistic?.lastSample?.rwValue;
  try {
    lineValue = checkValueAgainstFlags(flagsSampleValue, lineNumber, lineSampleValue);
  } catch (error) {
    // TODO: Enable logging once firmware is released to production. Right now, unstable firmware causes noisy logs.
    // rollbarLogger.error('[compute-power-meter-summary-metrics] Error handling line value', {
    //   key: `line${lineNumber}${startCase(valueType)}`,
    //   sampleValue: lineSampleValue,
    //   sampleDate: lineStatistic?.lastSample?.date,
    //   flags: flagsSampleValue,
    //   error
    // });
    if (error instanceof DetectedBitAndSampleReceivedMismatchError) {
      lineValue = DETECTED_BIT_AND_SAMPLE_RECEIVED_MISMATCH;
    }
  }
  return lineValue;
};

const calculateCurrentOrVoltageSummary: CalculateCurrentOrVoltageSummaryFunction = (
  valueType: 'current' | 'voltage',
  { line1Statistic, line2Statistic, line3Statistic },
  { flagsSampleValue }
) => {
  if (isNil(flagsSampleValue)) { // Without the flags, we cannot determine the current sampleValues correctly.
    return {
      line1: null,
      line2: null,
      line3: null
    };
  }

  const latestLine1Value: LineValue = getValueAndHandleError(valueType, flagsSampleValue, 1, line1Statistic);
  const latestLine2Value: LineValue = getValueAndHandleError(valueType, flagsSampleValue, 2, line2Statistic);
  const latestLine3Value: LineValue = getValueAndHandleError(valueType, flagsSampleValue, 3, line3Statistic);

  return {
    line1: latestLine1Value,
    line2: latestLine2Value,
    line3: latestLine3Value
  };
};

// ==============================
// MAIN EXPORT
// ==============================
/**
 * Performs calculations and unit conversions on the raw statistics data from the store
 * @param rawStatistic data in the store that has been merged over multiple sub sample points but has not undergone any
 * calculations (like summing, averaging, etc.)
 * @returns statistics ready for at-a-glance display (e.g. Map View, Sensor List, Sensor Detail Summary Card etc.)
 */
export const computePowerMeterSummaryMetrics = (rawStatistic: PowerMeterStatistic): SamplePoint['powerStatistics'] => {
  const {
    lastSample,
    energy
  } = rawStatistic;

  const flagsSampleValue: number | undefined = lastSample?.rwValue;
  const {
    isLine1Supported,
    isLine2Supported,
    isLine3Supported
  } = isNil(flagsSampleValue)
      ? {
        isLine1Supported: false,
        isLine2Supported: false,
        isLine3Supported: false
      } : checkSupportedLinesAgainstFlags(flagsSampleValue);

  // Calculate energy usage
  const energyToday: number | null = energy.aggregates.today?.values?.sum ?? null;
  const energyLast7Days: number | null = energy.aggregates.rolling7Days?.values?.sum ?? null;
  const energyLast14Days: number | null = energy.aggregates.rolling14Days?.values?.sum ?? null;
  const energyPrevious7Days: number | null = isNil(energyLast7Days) || isNil(energyLast14Days)
    ? null
    : energyLast14Days - energyLast7Days;
  const energyTrend7Days = isNil(energyPrevious7Days) || isNil(energyLast7Days)
    ? null
    : trend(energyPrevious7Days, energyLast7Days);

  const totalMeterReading: number | null = energy.aggregates.totalMeterReading?.values?.sum ?? null;

  // Calculate voltage summary
  const {
    line1: latestLine1Voltage,
    line2: latestLine2Voltage,
    line3: latestLine3Voltage
  }: LinesSummary = calculateCurrentOrVoltageSummary('voltage', {
    line1Statistic: rawStatistic.line1Voltage,
    line2Statistic: rawStatistic.line2Voltage,
    line3Statistic: rawStatistic.line3Voltage
  }, {
    flagsSampleValue
  });

  // Calculate current summary
  const {
    line1: latestLine1Current,
    line2: latestLine2Current,
    line3: latestLine3Current
  }: LinesSummary = calculateCurrentOrVoltageSummary('current', {
    line1Statistic: rawStatistic.line1Current,
    line2Statistic: rawStatistic.line2Current,
    line3Statistic: rawStatistic.line3Current
  }, {
    flagsSampleValue
  });

  // Calculate power meter status
  const powerMeterStatus: PowerMeterStatus = lastSample?.extraValues.actualStatus
    ?? PowerMeterStatus.OFFLINE;

  // Calculate lines summary
  const lines: PowerLine[] = [];
  if (isLine1Supported) {
    lines.push({
      lineNumber: 1,
      current: latestLine1Current,
      voltage: latestLine1Voltage
    });
  }
  if (isLine2Supported) {
    lines.push({
      lineNumber: 2,
      current: latestLine2Current,
      voltage: latestLine2Voltage
    });
  }
  if (isLine3Supported) {
    lines.push({
      lineNumber: 3,
      current: latestLine3Current,
      voltage: latestLine3Voltage
    });
  }

  return {
    energyRolling7Days: energyLast7Days,
    energyToday,
    energyTrend7Days,
    lines,
    powerMeterStatus,
    totalMeterReading
  };
};