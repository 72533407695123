import { Modify } from 'utils/TypeScript/modify';

export type AssetTypeId = AssetTypeCode;

/**
 * All asset types we may receive from the backend, that may or may not be
 * supported by the frontend.
 *
 * The mapping between the raw asset types and the frontend asset types is in rawToParsedAssetTypeCodes().
 * Note that if the mapping is not present, the asset type will never be shown in the frontend.
 */
export enum RawAssetTypeCode {
  UNKNOWN = 0,
  WATER_TANK = 1,
  ELECTRIC_FENCE = 2,
  RAIN_GAUGE = 3,
  TROUGH = 4,
  DIESEL_TANK = 5,
  PIPE = 6,
  DAM = 7,
  BORE = 8,
  LIQUID_FERTILISER_TANK = 9,
  SOIL_MOISTURE = 10,
  SOIL_TEMP = 11,
  LINE_PRESSURE = 12,
  PETROL_TANK = 13,
  CAMERA = 14,
  SAFETY_CHECK_IN_SOS = 15,
  SAFETY_CHECK_IN_VISIT = 16,
  MACHINE_CONTROL = 17,
  POWER_METER_FLAGS = 18,
  POWER_METER_VOLTAGE = 19,
  POWER_METER_CURRENT = 20,
  POWER_METER_ENERGY = 21,
  TEMPERATURE = 22,
  HUMIDITY = 23,
  WIND = 24,
  WEATHER_STATION_BATTERY = 25,
  SOLAR_RADIATION = 26,
  BAROMETRIC_PRESSURE = 27,
  DEW_POINT = 28,
  WEATHER_STATION_CONTROLLER = 29,
  INTERNAL_SENSOR = 99,
  SECURITY_CAMERA = 1001
}

/**
 * Asset types used in the frontend.
 */
export enum AssetTypeCode {
  WATER_TANK = 1,
  RAIN_GAUGE = 3,
  TROUGH = 4,
  FUEL_TANK = 5,
  PIPE = 6,
  DAM = 7,
  BORE = 8,
  LIQUID_FERTILISER_TANK = 9,
  SOIL = 10,
  LINE_PRESSURE = 12,
  CAMERA = 14,
  SAFETY_CHECK_IN = 15,
  // We show it as pump control to customers.
  MACHINE_CONTROL = 17,
  POWER_METER = 18,
  TEMPERATURE = 22,
  HUMIDITY = 23,
  WIND = 24,
  WEATHER_STATION_BATTERY = 25,
  SOLAR_RADIATION = 26,
  BAROMETRIC_PRESSURE = 27,
  DEW_POINT = 28,
  WEATHER_STATION_CONTROLLER = 29,
  // @debt: All places using this should be updated to use INTERNAL_SENSOR,
  // or the RAW_TO_PARSED_ASSET_TYPE_CODE should be updated to map from INTERNAL_SENSOR to BATTERY.
  BATTERY = 99,
  SECURITY_CAMERA = 1001
}

export type RawAssetType = Modify<AssetType, { id: RawAssetTypeCode }>;

export default interface AssetType {
  id: AssetTypeId;
  name: string;
  description: string;
}

export const weatherStationRawAssetTypes = [
  RawAssetTypeCode.BAROMETRIC_PRESSURE,
  RawAssetTypeCode.DEW_POINT,
  RawAssetTypeCode.HUMIDITY,
  RawAssetTypeCode.RAIN_GAUGE,
  RawAssetTypeCode.SOLAR_RADIATION,
  RawAssetTypeCode.TEMPERATURE,
  RawAssetTypeCode.WIND,
  RawAssetTypeCode.WEATHER_STATION_BATTERY
];