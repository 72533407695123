import { message as antdMessage } from 'antd';
import intersection from 'lodash/intersection';

import rollbarLogger from 'config/rollbar';
import SamplePoint from 'types/models/samplePoint';
import Trigger from 'types/models/trigger';
import { ConditionType, EventLevel, ValueType } from 'types/trigger.enum';
import makeTriggerSpecialKey from 'utils/make-trigger-special-key';
import { spHasMultipleUnitTriggers } from 'utils/SamplePoints/sp-has-multiple-unit-triggers';

const createSpecialKeysForMultipleValueTypes = (
  valueTypes: ValueType[],
  samplePoint: SamplePoint,
  eventLevel: EventLevel,
  conditionType: ConditionType
): string[] => valueTypes.map((valType) => makeTriggerSpecialKey(
  samplePoint.id,
  eventLevel,
  valType,
  conditionType
));

/**
 * Getting trigger id from special keys or by challenging special keys permutations of units (value types).
 * e.g., When the user updates a single-unit trigger, like bore low level trigger, `getTriggerId` will get the trigger
 * by constructed `defaultSpecialKey`.
 *
 * When the user updates a multi-unit trigger, e.g., from a dam level-based trigger to a dam volume-based trigger, we
 * need to test all special keys permutations of value types (units) to find out the id of old level-based trigger.
 * It's because, unfortunately, in the trigger update queue we don't have the trigger id but only triggers' special
 * keys. With just the special key of a dam volume-based trigger, we don't know if there's an existing dam level-based
 * trigger needs to update or we need to create a new trigger.
 *
 * Therefore, the special key of the dam low volume trigger [Null.2.3.4] ([samplePointId, eventLevel, valueType,
 * conditionType])
 * will be tested against all following special keys permutations:
 *
 * [181.2.1.4] - dam low level trigger
 * [182.2.3.4] - dam low volume trigger
 * [183.2.4.4] - dam low reduced level trigger
 *
 * If trigger with special key [181.2.1.4] does exist, it means the user is updating a dam low level trigger to dam low
 * volume trigger. In this case, we will return the id of the dam low level trigger, to help update the existing dam
 * low level trigger. Vice versa, if the dam low level trigger doesn't exist, we will return undefined, to help create a
 * new dam low volume trigger.
 */
export function getTriggerId(
  samplePoint: SamplePoint,
  eventLevel: EventLevel,
  valueType: ValueType,
  conditionType: ConditionType,
  triggers: Record<string, Trigger>
): number | undefined {
  const defaultSpecialKey = makeTriggerSpecialKey(
    samplePoint.id,
    eventLevel,
    valueType,
    conditionType
  );
  const trigger: Trigger = triggers[defaultSpecialKey];

  if (trigger) return trigger.id;

  const valueTypesToTest: ValueType[] = [
    ValueType.REDUCED_LEVEL,
    ValueType.VOLUME,
    ValueType.VALUE
  ];
  const shouldCheckOtherUnits = spHasMultipleUnitTriggers(samplePoint)
    && valueTypesToTest.includes(valueType);

  if (shouldCheckOtherUnits) {
    const specialKeysToTest: string[] = createSpecialKeysForMultipleValueTypes(
      valueTypesToTest,
      samplePoint,
      eventLevel,
      conditionType
    );
    const availableSpecialKeys: string[] = intersection(
      Object.keys(triggers),
      specialKeysToTest
    );
    if (availableSpecialKeys.length === 1) {
      return triggers[availableSpecialKeys[0]].id;
    }
    // A rare case that should not happen. It means before we update the trigger, there're already more than one
    // triggers for the same type of trigger.
    if (availableSpecialKeys.length > 1) {
      antdMessage.error('Failed to save the trigger. Please refresh the page and delete the existing trigger.');
      rollbarLogger.error('More than one triggers created for the same type of trigger', {
        specialKeys: availableSpecialKeys.join(', ')
      });
    }
  }
  return undefined;
}
