import {
  AdvancedDamChartType,
  SimpleDamChartType,
  TankChartType,
  TroughDirectChartType
} from 'components/features/samplePoint/SamplePointSampleChart/sampleChartType.enum';
import {
  CHART_DEFAULT_DATE_RANGE,
  FLOW_CHART_DEFAULT_DATE_RANGE,
  POWER_METER_CHART_DEFAULT_DATE_RANGE,
  RAIN_GAUGE_CHART_DEFAULT_DATE_RANGE,
  SAFETY_CHECK_IN_DEFAULT_DATE_RANGE,
  WEATHER_CHART_DEFAULT_DATE_RANGE
} from 'constants/chart-date-range';
import AuthActionType from 'store/modules/auth/constants';
import SiteActionType from 'store/modules/sites/constants';
import { ApplicationActions } from 'store/types';
import { AssetTypeCode } from 'types/models/asset-type';
import { upsertSafeChildProperties } from 'utils/Object/properties/upsert-child-property';

import ActionType from './constants';
import { LocalUserSettingsState } from './types';

export const initialState: LocalUserSettingsState = {
  waterLevelChartType: TankChartType.LEVEL,
  fuelLevelChartType: null,
  liquidFertiliserLevelChartType: null,
  flowChartType: null,
  simpleDamChartType: SimpleDamChartType.VOLUME,
  advancedDamChartType: AdvancedDamChartType.VOLUME,
  troughDirectChartType: TroughDirectChartType.PERCENT,
  defaultChartDateRanges: {
    [AssetTypeCode.WATER_TANK]: CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.RAIN_GAUGE]: RAIN_GAUGE_CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.TROUGH]: CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.PIPE]: FLOW_CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.DAM]: CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.BORE]: CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.LIQUID_FERTILISER_TANK]: CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.SOIL]: CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.LINE_PRESSURE]: CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.FUEL_TANK]: CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.CAMERA]: CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.SECURITY_CAMERA]: CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.SAFETY_CHECK_IN]: SAFETY_CHECK_IN_DEFAULT_DATE_RANGE,
    [AssetTypeCode.MACHINE_CONTROL]: CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.POWER_METER]: POWER_METER_CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.TEMPERATURE]: WEATHER_CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.HUMIDITY]: WEATHER_CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.WIND]: WEATHER_CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.WEATHER_STATION_BATTERY]: CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.SOLAR_RADIATION]: WEATHER_CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.BAROMETRIC_PRESSURE]: WEATHER_CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.DEW_POINT]: WEATHER_CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.WEATHER_STATION_CONTROLLER]: CHART_DEFAULT_DATE_RANGE,
    [AssetTypeCode.BATTERY]: CHART_DEFAULT_DATE_RANGE
  },
  showChartList: false,
  showChartListForPowerMeter: undefined,
  comparisonSettings: null,
  defaultSiteId: undefined
};

function localUserSettingsReducer(
  state: LocalUserSettingsState = initialState,
  action: ApplicationActions
): LocalUserSettingsState {
  switch (action.type) {
    case ActionType.SET_LOCAL_USER_SETTINGS_WATER_LEVEL_CHART_TYPE:
      return {
        ...state,
        waterLevelChartType: action.payload
      };
    case ActionType.SET_LOCAL_USER_SETTINGS_FUEL_LEVEL_CHART_TYPE:
      return {
        ...state,
        fuelLevelChartType: action.payload
      };
    case ActionType.SET_LOCAL_USER_SETTINGS_LIQUID_FERTILISER_CHART_TYPE:
      return {
        ...state,
        liquidFertiliserLevelChartType: action.payload
      };
    case ActionType.SET_LOCAL_USER_SETTINGS_FLOW_CHART_TYPE:
      return {
        ...state,
        flowChartType: action.payload
      };
    case ActionType.SET_LOCAL_USER_SETTINGS_SIMPLE_DAM_CHART_TYPE:
      return {
        ...state,
        simpleDamChartType: action.payload
      };
    case ActionType.SET_LOCAL_USER_SETTINGS_ADVANCED_DAM_CHART_TYPE:
      return {
        ...state,
        advancedDamChartType: action.payload
      };
    case ActionType.SET_LOCAL_USER_SETTINGS_TROUGH_DIRECT_CHART_TYPE:
      return {
        ...state,
        troughDirectChartType: action.payload
      };
    case ActionType.SET_DEFAULT_CHART_DATE_RANGE:
      return {
        ...state,
        defaultChartDateRanges: {
          ...state.defaultChartDateRanges,
          [action.payload.assetTypeCode]: action.payload.dateRange
        }
      };
    case ActionType.SET_LOCAL_USER_SETTINGS_SHOW_CHART_LIST:
      return {
        ...state,
        showChartList: action.payload
      };
    case ActionType.SET_LOCAL_USER_SETTINGS_SHOW_CHART_LIST_FOR_POWER_METER:
      return upsertSafeChildProperties(
        state,
        ['showChartListForPowerMeter'],
        { [action.payload.rawAssetType]: action.payload.showChart }
      );
    case SiteActionType.SET_SITES: {
      // If default site is not in sites, reset the default site.
      if (state.defaultSiteId && !(state.defaultSiteId in action.payload)) {
        return {
          ...state,
          defaultSiteId: undefined
        };
      }
      return state;
    }
    case SiteActionType.UNSET_SITE: {
      // If currently selected site is deleted, reset the default site.
      if (state.defaultSiteId === action.payload) {
        return {
          ...state,
          defaultSiteId: undefined
        };
      }
      return state;
    }
    case ActionType.SET_LOCAL_USER_SETTINGS_DEFAULT_SITE:
      return {
        ...state,
        defaultSiteId: action.payload?.id
      };
    case ActionType.SET_COMPARISON: {
      const { payload } = action;
      return {
        ...state,
        comparisonSettings: payload
      };
    }
    case ActionType.UNSET_COMPARISON: {
      return {
        ...state,
        comparisonSettings: null
      };
    }
    case AuthActionType.LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export default localUserSettingsReducer;
