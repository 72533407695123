import Sample from 'types/models/sample';
import { WindDirectionCategory } from 'types/wind.enum';

type WindDirectionResult = { normalisedDegrees: number; category: WindDirectionCategory };

export const getWindDirection = (degrees: number): WindDirectionResult => {
  let category: WindDirectionCategory;
  // Make sure all values fall within the range [0, 360)
  const normalisedDegrees = degrees % 360;
  // We use sixteen categories
  if (normalisedDegrees >= 348.75 || normalisedDegrees < 11.25) {
    category = WindDirectionCategory.N;
  } else if (normalisedDegrees < 33.75) {
    category = WindDirectionCategory.NNE;
  } else if (normalisedDegrees < 56.25) {
    category = WindDirectionCategory.NE;
  } else if (normalisedDegrees < 78.75) {
    category = WindDirectionCategory.ENE;
  } else if (normalisedDegrees < 101.25) {
    category = WindDirectionCategory.E;
  } else if (normalisedDegrees < 123.75) {
    category = WindDirectionCategory.ESE;
  } else if (normalisedDegrees < 146.25) {
    category = WindDirectionCategory.SE;
  } else if (normalisedDegrees < 168.75) {
    category = WindDirectionCategory.SSE;
  } else if (normalisedDegrees < 191.25) {
    category = WindDirectionCategory.S;
  } else if (normalisedDegrees < 213.75) {
    category = WindDirectionCategory.SSW;
  } else if (normalisedDegrees < 236.25) {
    category = WindDirectionCategory.SW;
  } else if (normalisedDegrees < 258.75) {
    category = WindDirectionCategory.WSW;
  } else if (normalisedDegrees < 281.25) {
    category = WindDirectionCategory.W;
  } else if (normalisedDegrees < 303.75) {
    category = WindDirectionCategory.WNW;
  } else if (normalisedDegrees < 326.25) {
    category = WindDirectionCategory.NW;
  } else {
    category = WindDirectionCategory.NNW;
  }

  return { normalisedDegrees, category };
};

export const getWindMeasurements = (sample: Sample) => {
  if (
    !sample.multiDimValues?.sampleDim
    || sample.multiDimValues.sampleDim.length !== 2
  ) {
    return undefined;
  }
  const [speedSample, directionSample] = sample.multiDimValues.sampleDim;
  const windSpeedMetric: number = speedSample.rwValue;
  const windDirectionCategory: WindDirectionCategory = getWindDirection(directionSample.rwValue).category;

  return { windSpeedMetric, windDirectionCategory };
};