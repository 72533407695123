import {
  Col,
  Input,
  Row,
  Select,
  Typography
} from 'antd';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';

import DefaultFormItem from 'components/composites/DefaultFormItem';
import AddressFormItems from 'components/forms/AddressFormItems';
import AdminUsersDropdown from 'components/forms/AdminUsersDropdown';
import FieldsValue from 'components/forms/FieldsValue';
import FieldValue from 'components/forms/FieldValue';
import FormItemCol from 'components/forms/FormItemCol';
import { ephemeralGetRequest } from 'store/modules/ephemeralRequest/actions';
import Enterprise, {
  EnterpriseStatus,
  EnterpriseSubdomain,
  Industry
} from 'types/models/enterprise';
import constructEnterpriseDomain from 'utils/construct-enterprise-domain';

import { DEFAULT_COUNTRY_ABBR_FARMBOT } from '../../../constants';
import FormItemEmail from '../FormItemEmail';
import FormItemTimezone from '../FormItemTimezone';
import './styles.less';

interface Props {
  /** Use responsive column width */
  isFullSpanOnMobileHalfOnDesktop?: boolean;
  readOnly?: boolean;
  isBackOffice?: boolean;
  isNewEnterprise?: boolean;
  initialValues?: Partial<Enterprise>;
  hasInitialValues?: boolean;
}

function EnterpriseFormItems({
  isFullSpanOnMobileHalfOnDesktop,
  readOnly,
  isBackOffice,
  isNewEnterprise,
  initialValues,
  hasInitialValues
}: Props): JSX.Element | null {
  const dispatch = useDispatch();
  const initialSubdomain: EnterpriseSubdomain | undefined =
    initialValues?.subdomain;
  return (
    <Row gutter={24}>
      <FormItemCol
        isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
      >
        <DefaultFormItem
          name="name"
          label="Enterprise Name"
          rules={[
            {
              required: true,
              message: 'Please enter an enterprise name'
            }
          ]}
          readOnly={readOnly}
        >
          <Input />
        </DefaultFormItem>
      </FormItemCol>

      {isBackOffice && (
        <>
          <FormItemCol
            isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
          >
            <DefaultFormItem
              name="subdomain"
              style={{ marginBottom: '4px' }}
              label="Enterprise URL subdomain"
              hasFeedback
              validateFirst
              normalize={(value) => value?.toLowerCase()}
              rules={[
                {
                  required: true,
                  min: 5,
                  max: 40,
                  message: 'Must be 5-40 characters long.'
                },
                {
                  required: true,
                  pattern: /^[a-z0-9-]*$/,
                  message: 'Only lowercase letters, numbers or hyphens.'
                },
                {
                  pattern: /^[^-].*[^-]$/,
                  message: 'Cannot begin or end with a hyphen.'
                },
                {
                  validator: (_, value) =>
                    new Promise<void>((resolve, reject) => {
                      if (value) {
                        if (value === initialSubdomain) {
                          resolve();
                        } else {
                          // Checks if chosen subdomain (value) is unique.
                          dispatch(
                            ephemeralGetRequest(
                              `enterprise/subdomain/${value}`,
                              resolve,
                              reject
                            )
                          );
                        }
                      } else {
                        reject(new Error('Please enter a subdomain.'));
                      }
                    })
                }
              ]}
              readOnly={readOnly}
            >
              <Input autoComplete="off" />
            </DefaultFormItem>

            <FieldsValue
              names={['subdomain', 'id', 'billingAddress', 'industry']}
            >
              {({
                subdomain,
                id,
                billingAddress,
                industry
              }: Partial<Enterprise>) => {
                const enterpriseDomain = constructEnterpriseDomain({
                  subdomain,
                  id,
                  billingAddress,
                  industry
                });

                return (
                  <div className="EnterpriseFormItems-SubdomainPreview">
                    {subdomain && (
                      <Typography.Text copyable>
                        {enterpriseDomain}
                      </Typography.Text>
                    )}
                  </div>
                );
              }}
            </FieldsValue>
          </FormItemCol>
          <FormItemCol
            isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
          >
            <DefaultFormItem
              name="industry"
              label="Industry"
              rules={[
                {
                  required: true,
                  message: 'Please select an industry'
                }
              ]}
              readOnly={readOnly}
            >
              <Select
                options={[
                  { label: 'Agriculture', value: Industry.AGRICULTURE },
                  { label: 'Mining', value: Industry.MINING }
                ]}
              />
            </DefaultFormItem>
          </FormItemCol>
          <FormItemCol
            isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
          >
            <DefaultFormItem
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: 'Please select an status'
                }
              ]}
              readOnly={readOnly}
            >
              <Select
                options={[
                  { label: 'Active', value: EnterpriseStatus.ACTIVE },
                  { label: 'Demo', value: EnterpriseStatus.DEMO },
                  { label: 'Disabled', value: EnterpriseStatus.DISABLED },
                  { label: 'Duplicate', value: EnterpriseStatus.DUPLICATE },
                  { label: 'Past due', value: EnterpriseStatus.PAST_DUE },
                  { label: 'Test', value: EnterpriseStatus.TEST },
                  { label: 'Trial', value: EnterpriseStatus.TRIAL },
                  { label: 'Trial expired', value: EnterpriseStatus.TRIAL_EXPIRED }
                ]}
              />
            </DefaultFormItem>
          </FormItemCol>
          <FormItemCol
            isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
          >
            <DefaultFormItem
              name="note"
              label="Farmbot Internal Notes"
              readOnly={readOnly}
            >
              <Input.TextArea maxLength={2000} />
            </DefaultFormItem>
          </FormItemCol>
        </>
      )}
      <>
        <Col span={24}>
          <Typography.Title level={5}>{`${isNewEnterprise ? 'Invite ' : ''}Enterprise Owner`}</Typography.Title>
        </Col>
        {isNewEnterprise ? (
          <Col span={24}>
            <FormItemEmail />
          </Col>
        ) : (
          <FieldsValue names={['status']} >
            {({ status }: { status?: EnterpriseStatus }) => (
              <AdminUsersDropdown
                readOnly={readOnly}
                isEnterprise
                isNewEnterprise={isNewEnterprise}
                initialValues={initialValues}
                hasInitialValues={hasInitialValues}
                isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
                isBackOffice={isBackOffice}
                // Disable owner-related validation if enterprise is or is being set to disabled.
                // This flag gets passed down to most of its children.
                skipValidation={status === EnterpriseStatus.DISABLED}
              />
            )}
          </FieldsValue>
        )}
      </>
      <Col span={24}>
        <Typography.Title level={5}>Billing Address</Typography.Title>
      </Col>
      <FieldValue name={['billingAddress', 'country']}>
        {(countryAbbreviation) => (
          <AddressFormItems
            topLevelName="billingAddress"
            isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
            readOnly={readOnly}
            hasInitialValues={hasInitialValues}
            countryAbbreviation={countryAbbreviation as string}
          />
        )}
      </FieldValue>
      {isNewEnterprise && (
        <FormItemCol
          isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
        >
          <FieldValue name={['billingAddress', 'country']}>
            {(countryAbbreviation) => (
              <FormItemTimezone
                formItemName="timezoneCode"
                readOnly={readOnly}
                countryAbbreviation={
                  (countryAbbreviation as string) ||
                  DEFAULT_COUNTRY_ABBR_FARMBOT
                }
              />
            )}
          </FieldValue>
        </FormItemCol>
      )}
    </Row>
  );
}

export default memo(EnterpriseFormItems);
