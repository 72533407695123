import cloneDeep from 'lodash/cloneDeep';

import { DETECTED_BIT_AND_SAMPLE_RECEIVED_MISMATCH } from 'constants/power-meter';
import { LineValue, PowerLine, PowerMeterSample, PowerMeterSampleWithKnownFlags } from 'types/models/power-meter';
import {
  DetectedBitAndSampleReceivedMismatchError,
  getCurrentValueAgainstFlags,
  getVoltageValueAgainstFlags
} from 'utils/Sample/power-meter';

const adjustLineValueInSample = (
  sample: PowerMeterSampleWithKnownFlags,
  key: keyof Pick<PowerMeterSample, 'line1Voltage' | 'line1Current' | 'line2Voltage' | 'line2Current' | 'line3Voltage' | 'line3Current'>
): void => {
  const getValueAgainstFlags = ['line1Voltage', 'line2Voltage', 'line3Voltage'].includes(key)
    ? getVoltageValueAgainstFlags
    : getCurrentValueAgainstFlags;
  const lineNumber: PowerLine['lineNumber'] = ['line1Voltage', 'line1Current'].includes(key)
    ? 1
    : ['line2Voltage', 'line2Current'].includes(key)
      ? 2
      : 3;
  let value: LineValue = null;
  try {
    value = getValueAgainstFlags(sample.flags.rwValue, lineNumber, sample[key]?.rwValue);
  } catch (error) {
    // TODO: Enable logging once firmware is released to production. Right now, unstable firmware causes noisy logs.
    // rollbarLogger.error('[merge-power-meter-samples] Error handling line value', {
    //   key,
    //   sampleValue: sample[key]?.rwValue,
    //   sampleDate: sample.date,
    //   flags: sample.flags.rwValue,
    //   error
    // });
    if (error instanceof DetectedBitAndSampleReceivedMismatchError) {
      value = DETECTED_BIT_AND_SAMPLE_RECEIVED_MISMATCH;
    }
  }
  if (value === null) {
    delete sample[key];
  } else if (sample[key]) {
    sample[key]!.rwValue = value; // Assertion needed because TypeScript just can't infer despite the if
  }
};

export const handleLineValuesAgainstFlags = (sample: PowerMeterSampleWithKnownFlags): PowerMeterSampleWithKnownFlags => {
  const result = cloneDeep(sample);
  if (sample.line1Current) adjustLineValueInSample(result, 'line1Current');
  if (sample.line2Current) adjustLineValueInSample(result, 'line2Current');
  if (sample.line3Current) adjustLineValueInSample(result, 'line3Current');
  if (sample.line1Voltage) adjustLineValueInSample(result, 'line1Voltage');
  if (sample.line2Voltage) adjustLineValueInSample(result, 'line2Voltage');
  if (sample.line3Voltage) adjustLineValueInSample(result, 'line3Voltage');
  return result;
};