import { LINE_MISSING_INDICATOR } from 'constants/power-meter';
import { LineValue, PowerLine } from 'types/models/power-meter';
import Sample from 'types/models/sample';

import { type Nibble } from './bit';
import { parsePowerMeterFlagsSample } from './flags-parser';
import { checkLinePresence } from './line-presence';

export class DetectedBitAndSampleReceivedMismatchError extends Error {
  constructor(lineNibble: Nibble, sampleValue: Sample['rwValue'] | undefined) {
    super(`Detected bit and sample received mismatch, flags "${lineNibble}" received ${sampleValue}`);
  }
}

// ==============================
// INTERNAL HELPERS
// ==============================
const getLineValueAgainstFlags = (
  lineNibble: Nibble,
  sampleValue: Sample['rwValue'] | undefined
): LineValue => {
  const isSampleReceived = sampleValue !== undefined;
  const { isExpectedPresent, isActuallyPresent } = checkLinePresence(lineNibble);
  // Case 1: Line is not expected, do not display on UI or handle alerts.
  if (!isExpectedPresent) return null;
  // Case 2: Line is expected and its sample is received. Display that on UI, regardless of the detectedBit.
  if (isSampleReceived) return sampleValue;
  // Case 3: Line is expected and detected, but it received no sample. Likely a firmware bug.
  if (isActuallyPresent) throw new DetectedBitAndSampleReceivedMismatchError(lineNibble, sampleValue);
  // Case 4: Line is expected but its value is missing.
  return LINE_MISSING_INDICATOR;
};

// ==============================
// EXPORTS
// ==============================
export const getCurrentValueAgainstFlags = (
  flagsSampleValue: Sample['rwValue'],
  lineNumber: PowerLine['lineNumber'],
  currentSampleValue: Sample['rwValue'] | undefined
): LineValue => {
  const { l1c, l2c, l3c } = parsePowerMeterFlagsSample(flagsSampleValue) || {};
  switch (lineNumber) {
    case 1: {
      if (!l1c) return null;
      return getLineValueAgainstFlags(l1c, currentSampleValue);
    }
    case 2: {
      if (!l2c) return null;
      return getLineValueAgainstFlags(l2c, currentSampleValue);
    }
    case 3: {
      if (!l3c) return null;
      return getLineValueAgainstFlags(l3c, currentSampleValue);
    }
    default:
      return null;
  }
};

export const getVoltageValueAgainstFlags = (
  flagsSampleValue: Sample['rwValue'],
  lineNumber: PowerLine['lineNumber'],
  voltageSampleValue: Sample['rwValue'] | undefined
): LineValue => {
  const { l1v, l2v, l3v } = parsePowerMeterFlagsSample(flagsSampleValue) || {};
  switch (lineNumber) {
    case 1: {
      if (!l1v) return null;
      return getLineValueAgainstFlags(l1v, voltageSampleValue);
    }
    case 2: {
      if (!l2v) return null;
      return getLineValueAgainstFlags(l2v, voltageSampleValue);
    }
    case 3: {
      if (!l3v) return null;
      return getLineValueAgainstFlags(l3v, voltageSampleValue);
    }
    default:
      return null;
  }
};