import React from 'react';
import { IconType } from 'react-icons';

import { ReactComponent as CloudRainIcon } from './cloudRainIcon.svg';

const FarmbotCloudRainIcon: IconType = ({ size, color }) => {
  return <CloudRainIcon width={size ?? 18} height={size} color={color} />;
};

export default FarmbotCloudRainIcon;
