import { PowerMeterSample } from 'types/models/power-meter';
import Sample, { ControlPointSample, SampleDate, SampleId } from 'types/models/sample';
import { SamplePointId } from 'types/models/samplePoint';

export type SamplesState = {
  samplePointSamples: Record<SampleId, Sample>;
  controlPointSamples: Record<SampleId, ControlPointSample>;
  powerMeterSamples: Record<SampleDate, PowerMeterSample>;
};

export enum SampleInterval {
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly'
}

export type ConnectedSamplePointIds = { destination?: SamplePointId, source?: SamplePointId };