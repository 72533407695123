import { AssetTypeCode } from 'types/models/asset-type';
import Sample from 'types/models/sample';
import SamplePoint, { SamplePointId } from 'types/models/samplePoint';
import { WEATHER_ASSET_TYPE_TO_UNIT_TYPE } from 'types/weather.enum';
import convertMetricToImperial from 'utils/convert-metric-to-imperial';
import { UnitType } from 'utils/get-unit-by-country';

export function getUnitTypeByAssetTypeId(assetTypeId: number): UnitType {
  switch (assetTypeId) {
    case AssetTypeCode.PIPE:
      return UnitType.VOLUME_SM;
    case AssetTypeCode.RAIN_GAUGE:
      return UnitType.RAIN;
    case AssetTypeCode.LINE_PRESSURE:
      return UnitType.PRESSURE_LG;
    case AssetTypeCode.BATTERY:
      return UnitType.TREND_SM;
    case AssetTypeCode.BORE:
      return UnitType.LEVEL_MD;
    case AssetTypeCode.WATER_TANK:
    case AssetTypeCode.FUEL_TANK:
    case AssetTypeCode.LIQUID_FERTILISER_TANK:
    case AssetTypeCode.DAM:
    case AssetTypeCode.TROUGH:
    default:
      return WEATHER_ASSET_TYPE_TO_UNIT_TYPE[assetTypeId] ?? UnitType.LEVEL_SM;
  }
}

export function parseSample(
  sample: Sample,
  samplePointId: SamplePointId,
  assetTypeId: AssetTypeCode,
  country: string
) {
  if ([
    AssetTypeCode.BORE,
    AssetTypeCode.DAM,
    // Power-related units (V, A, kWh) do not need conversion between metric and imperial
    AssetTypeCode.POWER_METER
  ].includes(assetTypeId)) {
    return {
      ...sample,
      samplePointId: samplePointId,
      rwValue: sample.rwValue
    };
  }

  if ([AssetTypeCode.TEMPERATURE, AssetTypeCode.DEW_POINT].includes(assetTypeId)) {
    // Do not replace samplePointId
    return sample;
  }

  // TODO: Need to gradually move unit conversion closer to UI layer, because
  // some calculation later needs metric values. Bore is the first step.
  // @uom-refactor
  const rwValue: number = convertMetricToImperial(
    getUnitTypeByAssetTypeId(assetTypeId),
    country,
    sample.rwValue
  ) || 0;

  return {
    ...sample,
    samplePointId: samplePointId,
    rwValue
  };
};

interface IParseSamples {
  samples: Sample[];
  samplePoint: SamplePoint;
  country: string;
}

export function parseSamples({
  samples, samplePoint, country
}: IParseSamples): Sample[] {
  return samples.map((sample) => parseSample(sample, samplePoint.id, samplePoint.assetTypeId, country));
}
