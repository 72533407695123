import { AssetTypeCode } from 'types/models/asset-type';
import { UnitType } from 'utils/get-unit-by-country';

const WEATHER_ASSET_TYPES = [
  AssetTypeCode.BAROMETRIC_PRESSURE,
  AssetTypeCode.DEW_POINT,
  AssetTypeCode.HUMIDITY,
  AssetTypeCode.RAIN_GAUGE,
  AssetTypeCode.SOLAR_RADIATION,
  AssetTypeCode.TEMPERATURE,
  AssetTypeCode.WEATHER_STATION_BATTERY,
  AssetTypeCode.WIND
] as const;

const WEATHER_ASSET_TYPES_SET: Set<AssetTypeCode> = new Set(WEATHER_ASSET_TYPES);

export const isWeatherAssetType = (assetTypeId: AssetTypeCode): boolean => {
  return WEATHER_ASSET_TYPES_SET.has(assetTypeId);
};

export type WeatherAssetType = typeof WEATHER_ASSET_TYPES[number];

export type WeatherUnitType =
  | UnitType.HUMIDITY
  | UnitType.PRESSURE_MD
  | UnitType.RAIN
  | UnitType.SOLAR_RADIATION
  | UnitType.TEMPERATURE
  | UnitType.VOLTAGE
  | UnitType.WIND_SPEED;

export const WEATHER_ASSET_TYPE_TO_LABEL: Record<WeatherAssetType, string> = {
  [AssetTypeCode.TEMPERATURE]: 'Temperature',
  [AssetTypeCode.DEW_POINT]: 'Dew Point',
  [AssetTypeCode.RAIN_GAUGE]: 'Rainfall',
  [AssetTypeCode.HUMIDITY]: 'Humidity',
  [AssetTypeCode.WIND]: 'Wind',
  [AssetTypeCode.BAROMETRIC_PRESSURE]: 'Barometric Pressure',
  [AssetTypeCode.SOLAR_RADIATION]: 'Solar Radiation',
  [AssetTypeCode.WEATHER_STATION_BATTERY]: 'Battery'
};

// This mapping shares some similarity with getUnitTypeByAssetTypeId in src/store/modules/samples/saga/utils.ts
// but here it is narrower in scope in that it returns only WeatherUnitType.
export const WEATHER_ASSET_TYPE_TO_UNIT_TYPE: Record<WeatherAssetType, WeatherUnitType> = {
  [AssetTypeCode.TEMPERATURE]: UnitType.TEMPERATURE,
  [AssetTypeCode.DEW_POINT]: UnitType.TEMPERATURE,
  [AssetTypeCode.RAIN_GAUGE]: UnitType.RAIN,
  [AssetTypeCode.HUMIDITY]: UnitType.HUMIDITY,
  [AssetTypeCode.WIND]: UnitType.WIND_SPEED,
  [AssetTypeCode.BAROMETRIC_PRESSURE]: UnitType.PRESSURE_MD,
  [AssetTypeCode.SOLAR_RADIATION]: UnitType.SOLAR_RADIATION,
  [AssetTypeCode.WEATHER_STATION_BATTERY]: UnitType.VOLTAGE
};

/** Doc: https://farmbot-au.atlassian.net/wiki/spaces/DEV/pages/2267938817/Product+Spec+Weather+Stations#Weather-Sensor-Priority-List */
export const WEATHER_ASSET_TYPE_TO_PREFERRED_DISPLAY_ORDER: Record<WeatherAssetType, number> = {
  [AssetTypeCode.TEMPERATURE]: 1,
  [AssetTypeCode.DEW_POINT]: 2,
  [AssetTypeCode.RAIN_GAUGE]: 3,
  [AssetTypeCode.HUMIDITY]: 4,
  [AssetTypeCode.WIND]: 5,
  [AssetTypeCode.BAROMETRIC_PRESSURE]: 6,
  [AssetTypeCode.SOLAR_RADIATION]: 7,
  // Evapotranspiration: 8 // TODO: https://farmbot.atlassian.net/browse/FMBT-7352
  // Delta-T: 9 // TODO: https://farmbot.atlassian.net/browse/FMBT-7353
  [AssetTypeCode.WEATHER_STATION_BATTERY]: 10
};