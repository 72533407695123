import { AssetTypeCode } from 'types/models/asset-type';
import { UnitOfTime } from 'types/models/time';

export const integratedAssetTypes = [AssetTypeCode.SECURITY_CAMERA];
export const CIBO_MATTER_PRECISION = 0;
export const CIBO_AREA_PRECISION = 0;
export const CIBO_LOCATION_ADJUSTMENT_PERIOD_IN_DAYS = 14;
export const CIBO_SUGGESTED_ZOOM_LEVEL: number = 11;

// To obtain 6 hours of weather data including the latest hour, we look back 5 hours
export const WEATHER_DASHBOARD_LOOKBACK_HOURS = 5;
export const WEATHER_DASHBOARD_TIME_UNIT: UnitOfTime = 'hour';
