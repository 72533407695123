import { Select, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { FiInfo } from 'react-icons/fi';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import ContactSummary from 'components/composites/ContactSummary';
import DefaultFormItem from 'components/composites/DefaultFormItem';
import LoadingSpinner from 'components/composites/LoadingSpinner';
import FieldValue from 'components/forms/FieldValue';
import FormItemCol from 'components/forms/FormItemCol';
import UserFormItems from 'components/forms/UserFormItems';
import useBackOfficeEnterprise from 'store/modules/backOfficeEnterprise/hooks';
import { selectCurrentEnterpriseId } from 'store/modules/enterprise/selectors';
import { ephemeralGetRequest } from 'store/modules/ephemeralRequest/actions';
import { makeSelectUserRoleGranted } from 'store/modules/myUser/selectors';
import { useUser } from 'store/modules/users/hooks';
import Enterprise from 'types/models/enterprise';
import { UserRole } from 'types/models/membership';
import Site from 'types/models/site';
import User from 'types/models/user';

const PRIMARY_CONTACT_TOOLTIP_LINK =
  'https://intercom.help/farmbot-helpcentre/en/articles/5486656-what-is-the-difference-between-an-admin-supervisor-and-view-only-role';

interface Props {
  readOnly?: boolean;
  isEnterprise?: boolean;
  isNewEnterprise?: boolean;
  isBackOffice?: boolean;
  initialValues?: Partial<Enterprise> | Partial<Site>;
  hasInitialValues?: boolean;
  /** Use responsive column width */
  isFullSpanOnMobileHalfOnDesktop?: boolean;
  skipValidation?: boolean;
}

// TODO: FMBT-2870 To be refactored
function AdminUsersDropdown({
  readOnly,
  isEnterprise,
  isNewEnterprise,
  isBackOffice,
  initialValues,
  hasInitialValues,
  isFullSpanOnMobileHalfOnDesktop,
  skipValidation
}: Props): JSX.Element | null {
  const dispatch = useDispatch();
  const [enterpriseAdmins, setEnterpriseAdmins] = useState<User[]>();
  const owner = initialValues?.owner;
  const ownerOptionsLabel = isEnterprise
    ? 'Enterprise Owner'
    : 'Primary Contact';

  const currentEnterpriseId = useSelector(selectCurrentEnterpriseId, shallowEqual);
  const currentBackOfficeEnterpriseId = useBackOfficeEnterprise()?.id;
  const enterpriseId = isBackOffice
    ? currentBackOfficeEnterpriseId
      ? currentBackOfficeEnterpriseId
      : initialValues?.id
    : currentEnterpriseId;

  const isAdmin = useSelector(
    makeSelectUserRoleGranted([UserRole.ADMIN]),
    shallowEqual
  );

  const adminUsers: User[] = useUser(UserRole.ADMIN);

  useEffect(() => {
    // Request should always be called if enterpriseId has changed regardless of current adminUsers content
    if (enterpriseId) {
      dispatch(
        ephemeralGetRequest(
          `enterprise/${enterpriseId}/users?user_role=${UserRole.ADMIN}`,
          setEnterpriseAdmins,
          () => { }
        )
      );
    } else {
      setEnterpriseAdmins(adminUsers);
    }
  }, [dispatch, enterpriseId, adminUsers]);

  if (!enterpriseAdmins) {
    return (
      <div style={{ position: 'relative', width: '100%', height: '100px' }}>
        <LoadingSpinner />
      </div>
    );
  }

  const users: User[] = enterpriseAdmins.filter(
    (user: User) => !user.isSuperAdmin
  );

  const ownerOptions = users.map(({ firstName, lastName, id }) => ({
    label: `${firstName} ${lastName}`,
    value: id
  }));

  if (ownerOptions.length > 0) {
    return (
      <>
        <FormItemCol
          isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
        >
          <DefaultFormItem
            label={(
              <>
                <Typography.Text>
                  {' '}
                  {ownerOptionsLabel}
                  {' '}
                </Typography.Text>
                <Tooltip
                  style={{
                    marginLeft: '15px'
                  }}
                  title={(
                    <>
                      {ownerOptionsLabel}
                      {' '}
                      must be an Admin user.
                      <Typography.Link
                        href={PRIMARY_CONTACT_TOOLTIP_LINK}
                        target="_blank"
                        style={{
                          marginLeft: '5px',
                          textDecoration: 'underline'
                        }}
                      >
                        Learn more.
                      </Typography.Link>
                    </>
                  )}
                >
                  <FiInfo size="14" style={{ position: 'relative', top: 2 }} />
                </Tooltip>
              </>
            )}
            name="ownerId"
            initialValue={hasInitialValues ? undefined : ownerOptions[0]?.value}
            rules={[
              {
                required: !skipValidation,
                message: 'Please select an Owner'
              }
            ]}
          >
            {readOnly ? (
              <FieldValue name="ownerId">
                {(ownerId) =>
                  ownerOptions.find(({ value }) => value === ownerId)?.label ??
                  ''}
              </FieldValue>
            ) : (
              <Select options={ownerOptions} />
            )}
          </DefaultFormItem>
        </FormItemCol>
        <FormItemCol
          isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
        >
          <FieldValue name="ownerId">
            {(ownerId) => {
              const user: User | undefined =
                ownerId !== owner?.id
                  ? users.find((item) => item.id === ownerId)
                  : owner;
              return (
                <>
                  <Typography.Text strong>Contact Details</Typography.Text>
                  <ContactSummary contact={user} />
                </>
              );
            }}
          </FieldValue>
        </FormItemCol>
      </>
    );
  }
  return (
    <FormItemCol
      isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
    >
      <FieldValue name={['owner']}>
        {() => (
          <UserFormItems
            initialValues={owner}
            isAdmin={isAdmin}
            isNewEnterprise={isNewEnterprise}
            isFullSpanOnMobileHalfOnDesktop={isFullSpanOnMobileHalfOnDesktop}
            required={!skipValidation}
          />
        )}
      </FieldValue>
    </FormItemCol>
  );
}

export default AdminUsersDropdown;
