/**
 * Request statuses of all the sample points for the current enterprise.
 */

import axios from 'axios';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

import { getDateKey } from 'components/features/camera/helpers';
import rollbarLogger from 'config/rollbar';
import { DEFAULT_TIMEZONE_CODE } from 'constants/time';
import { selectSamplePointsAsArray } from 'store/modules/samplePoints/selectors';
import { AssetTypeCode } from 'types/models/asset-type';
import {
  CameraImageStatus,
  CameraImageType,
  CameraStatus
} from 'types/models/camera';
import {
  MergedSamplePoint,
  SamplePointId
} from 'types/models/samplePoint';
import SamplePointStatistic, { MergedSamplePointStatistic } from 'types/models/samplePointsStatistic';
import { getMergedStatisticForMachineControl } from 'utils/associated-sample-points/get-merged-statistic-for-machine-control';
import { getMergedStatisticForPowerMeter } from 'utils/associated-sample-points/get-merged-statistic-for-power-meter';
import { getMergedStatisticForSafetyCheckIn } from 'utils/associated-sample-points/get-merged-statistic-for-safety-check-in';
import { getMergedStatisticForSoil } from 'utils/associated-sample-points/get-merged-statistic-for-soil';
import { AssociatedPowerMeterSamplePoints } from 'utils/associated-sample-points/merge-power-meters';
import { getRequest } from 'utils/redux-saga-requests';

import {
  loadSamplePointsStatistics,
  loadSamplePointsStatisticsFailure,
  loadSamplePointsStatisticsSuccess,
  setSamplePointsStatistics
} from './actions';
import ActionTypes from './constants';
import { SamplePointsStatisticsState } from './types';
import {
  loadCameraImages,
  loadCameraImagesSuccess,
  loadCameraStatusSuccess
} from '../cameras/actions';
import { makeSelectCameraStatus } from '../cameras/selectors';
import { CamerasState } from '../cameras/types';
import mockMachineControls from '../controlPoints/__test__/data/mockMachineControls';

/** A temporary solution as BE can't provide the image id */
export const DEFAULT_COVER_IMAGE_ID = -1;

function getMergedStatisticByAssetType(
  allStatistics: Record<number, SamplePointStatistic>,
  mainSamplePoint: MergedSamplePoint
): MergedSamplePointStatistic {
  try {
    switch (mainSamplePoint.assetTypeId) {
      case AssetTypeCode.SOIL:
        return getMergedStatisticForSoil(allStatistics, mainSamplePoint);
      case AssetTypeCode.SAFETY_CHECK_IN:
        return getMergedStatisticForSafetyCheckIn(allStatistics, mainSamplePoint);
      case AssetTypeCode.MACHINE_CONTROL:
        return getMergedStatisticForMachineControl(allStatistics, mainSamplePoint);
      case AssetTypeCode.POWER_METER: {
        const powerMeterStatistic = getMergedStatisticForPowerMeter(
          allStatistics,
          mainSamplePoint as MergedSamplePoint<AssociatedPowerMeterSamplePoints>
        );
        return powerMeterStatistic ?? allStatistics[mainSamplePoint.id];
      }
      default:
        return allStatistics[mainSamplePoint.id];
    }
  } catch (error) {
    rollbarLogger.error('Error merging statistic for sample point', { id: mainSamplePoint.id, error });
    return allStatistics[mainSamplePoint.id];
  }
}

/**
 * Parse statistics based on sample points. If the sample point is merged into main sample point, its statistics will
 * not be parsed and visible.
 * */
export function* parseSamplePointStatistics(
  samplePointOrControlPointStatistics: SamplePointsStatisticsState['data']
) {
  // Be careful. The content of samplePointStatistics is subject to change.
  // It can be a list of control points or sample points.
  const samplePoints: MergedSamplePoint[] = (
    (yield select(selectSamplePointsAsArray))
  ).map((sp: MergedSamplePoint) => ({
    ...sp,
    // Update the sample points in store by the latest lastSampleDate
    // If samplePointStatistics[sp.id] is a control point, it doesn't have lastSample
    lastSampleDate:
      samplePointOrControlPointStatistics[sp.id]?.lastSample?.date ??
      sp.lastSampleDate
  }));

  // Expose secondary machine control in the store for its status
  for (const samplePoint of samplePoints) {
    if (
      samplePoint.assetTypeId === AssetTypeCode.MACHINE_CONTROL && samplePoint._hidden
    ) {
      samplePoints.push(samplePoint._hidden);
    }
  }

  return samplePoints.reduce((statistics, samplePoint) => {
    if (samplePointOrControlPointStatistics[samplePoint.id]) {
      /** Statistics of associated sample points need to be merged into the main sample point */
      const mergedStatistic: MergedSamplePointStatistic = getMergedStatisticByAssetType(
        samplePointOrControlPointStatistics,
        samplePoint
      );

      return {
        ...statistics,
        [samplePoint.id]: mergedStatistic
      };
    }
    return statistics;
  }, {});
}

export function* requestSamplePointsStatistics(
  action: ReturnType<typeof loadSamplePointsStatistics>
) {
  const {
    payload: { enterpriseId }
  } = action;

  try {
    const { data } = yield call(
      getRequest,
      `enterprise/v2/${enterpriseId}/status`
    );
    const samplePoints: MergedSamplePoint[] = yield select(
      selectSamplePointsAsArray
    );
    const hasControlPoints = samplePoints.some(
      (sp) => sp.assetTypeId === AssetTypeCode.MACHINE_CONTROL
    );
    if (hasControlPoints) {
      // For testing only. It works with mocks in 1) store/modules/enterpriseScaffold/saga.ts
      // 2) components/features/machineControl/MachineControlPanel/index.tsx
      if (process.env.REACT_APP_ENV !== 'production') {
        data.push(...mockMachineControls);
      }
    }

    const parsedStatistics: SamplePointsStatisticsState['data'] = yield call(
      parseSamplePointStatistics,
      keyBy(data, 'samplePointId')
    );

    for (const camera of data.filter(
      (sp: any) => sp.assetTypeId === AssetTypeCode.CAMERA
    )) {
      const { samplePointId, status, statusCode, updatedAt, url } = camera;
      const previousCameraStatus:
        | CamerasState[SamplePointId]['status']
        | undefined = yield select(makeSelectCameraStatus(samplePointId));
      // If the camera was processing but is now ready, fetch the new image.
      if (
        previousCameraStatus &&
        previousCameraStatus.status === CameraStatus.PROCESSING &&
        status === CameraImageStatus.READY
      ) {
        yield put(loadCameraImages(samplePointId, { page: 1 }) as any);
      }

      const samplePoint = samplePoints.find(sp => sp.id === samplePointId);
      const timezoneCode = samplePoint?.siteTimezoneCode || DEFAULT_TIMEZONE_CODE;

      yield put(loadCameraStatusSuccess(samplePointId, status, statusCode));
      // Add default image for each camera
      yield put(
        loadCameraImagesSuccess(
          samplePointId,
          {
            images: [
              {
                id: DEFAULT_COVER_IMAGE_ID,
                updatedAt,
                url,
                status,
                statusCode
              } as CameraImageType
            ],
            count: 1
          },
          1,
          getDateKey(updatedAt, timezoneCode)
        )
      );
    }

    yield all([
      put(loadSamplePointsStatisticsSuccess(data)),
      put(setSamplePointsStatistics(enterpriseId, parsedStatistics))
    ]);
  } catch (error) {
    if (!axios.isAxiosError(error)) throw error;

    const message = get(
      error,
      'response.data.message',
      'Sorry, something went wrong.'
    );
    yield put(loadSamplePointsStatisticsFailure(message, error));
  }
}

export function* watchLoadSamplePointsStatisticsRequest() {
  yield takeLatest(
    ActionTypes.LOAD_SAMPLE_POINTS_STATISTICS_REQUEST,
    requestSamplePointsStatistics
  );
}

export default function* samplePointsSaga() {
  yield all([fork(watchLoadSamplePointsStatisticsRequest)]);
}
