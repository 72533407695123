import lodashMerge from 'lodash/merge';

import rollbarLogger from 'config/rollbar';
import { PowerMeterSample, PowerMeterSampleWithKnownFlags } from 'types/models/power-meter';
import Sample, { SampleDate } from 'types/models/sample';
import SamplePoint, { SamplePointId } from 'types/models/samplePoint';
import { assertIsDefined } from 'utils/TypeScript/assert-is-defined';

import {
  BackwardSearchExhaustedError,
  ForwardSearchExhaustedError,
  populateFlagsAndBackfillLines
} from './handle-backfills';
import { handleLineValuesAgainstFlags } from './handle-lines';
import { getOriginalSamplePointIdsFromMergedPowerMeter } from '../get-original-sample-point-ids-from-merged-sample-point';

/**
 * Merges samples from multiple sub-sample points of a power meter into a single map.
 * The resulting map contains entries sorted by date in descending order (newest first).
 * Each entry maps a date to a power meter sample that combines data from all relevant sub-sample points.
 * Diagram: https://farmbot-au.atlassian.net/wiki/spaces/DEV/pages/2304049165/Power+Meter+data+structures+in+MFB+BE+FE#4.-FE---Merge-samples
 */
export const mergePowerMeterSamplesNewestFirst = (
  requestSamplePointIds: SamplePointId[],
  samplesFromAssociatedSamplePoints: Sample[][],
  mainSamplePoint: SamplePoint
): Map<number, PowerMeterSample> => {
  let flagsSamplePointId: SamplePointId;
  let energySamplePointId: SamplePointId;
  let line1VoltageSamplePointId: SamplePointId;
  let line1CurrentSamplePointId: SamplePointId;
  let line2VoltageSamplePointId: SamplePointId | undefined;
  let line2CurrentSamplePointId: SamplePointId | undefined;
  let line3VoltageSamplePointId: SamplePointId | undefined;
  let line3CurrentSamplePointId: SamplePointId | undefined;

  try {
    ({
      flagsSamplePointId,
      energySamplePointId,
      line1VoltageSamplePointId,
      line1CurrentSamplePointId,
      line2VoltageSamplePointId,
      line2CurrentSamplePointId,
      line3VoltageSamplePointId,
      line3CurrentSamplePointId
    } = getOriginalSamplePointIdsFromMergedPowerMeter(mainSamplePoint));
  } catch (error) {
    rollbarLogger.error('[merge-power-meter-samples] Could not retrieve sub-sample point ids', {
      flagsSamplePointId: mainSamplePoint.id,
      error
    });
    // Shape of inputs is not as expected, so we cannot guarantee proper output
    return new Map();
  }

  // The sub-sample points represented by requestSamplePointIds are in the same order as
  // the sub-sample points represented by samplesFromAssociatedSamplePoints.
  // We will use this order to match the samples with the appropriate merge key.
  const mergeKeys: (keyof PowerMeterSample | undefined)[] = requestSamplePointIds.map(currSamplePointId => {
    switch (currSamplePointId) {
      case flagsSamplePointId:
        return 'flags';
      case energySamplePointId:
        return 'energy';
      case line1VoltageSamplePointId:
        return 'line1Voltage';
      case line1CurrentSamplePointId:
        return 'line1Current';
      case line2VoltageSamplePointId:
        return 'line2Voltage';
      case line2CurrentSamplePointId:
        return 'line2Current';
      case line3VoltageSamplePointId:
        return 'line3Voltage';
      case line3CurrentSamplePointId:
        return 'line3Current';
      default:
        return;
    }
  });

  // ============================================================
  // STEP 1: Merge sub-samples by date, disregarding the chronological order
  // ============================================================
  const samplesByDateUnordered = new Map<SampleDate, PowerMeterSample>();
  const mergeSubSampleIntoMap = (mergeKey: keyof PowerMeterSample, subSample: Sample) => {
    const date: SampleDate = subSample.date;
    const existingRecord: PowerMeterSample | undefined = samplesByDateUnordered.get(date);
    const emptyRecord: PowerMeterSample = { date };
    const updatedRecord: PowerMeterSample = lodashMerge(existingRecord || emptyRecord, { [mergeKey]: subSample });
    samplesByDateUnordered.set(date, updatedRecord);
  };

  samplesFromAssociatedSamplePoints.forEach((samplesOfOneSamplePoint, index) => {
    if (samplesOfOneSamplePoint.length === 0) {
      return;
    }
    const mergeKey = mergeKeys[index];
    if (!mergeKey) return;
    samplesOfOneSamplePoint.forEach(sample => mergeSubSampleIntoMap(mergeKey, sample));
  });

  // ============================================================
  // STEP 2: Sort the merged samples by date, newest first
  // ============================================================
  const datesArrayNewestFirst = Array.from(samplesByDateUnordered.keys()).sort((a, b) => b - a);
  const samplesByDateNewestFirst = new Map<SampleDate, PowerMeterSample>();
  datesArrayNewestFirst.forEach((date, dateIndex) => {
    const sample = samplesByDateUnordered.get(date);
    const hasLineSubSamples: boolean = Boolean(sample && (
      sample.line1Voltage ||
      sample.line1Current ||
      sample.line2Voltage ||
      sample.line2Current ||
      sample.line3Voltage ||
      sample.line3Current
    ));
    if (hasLineSubSamples) {
      assertIsDefined(sample); // hasLineSubSamples guarantees sample is defined

      // 1) When sample has line sub-samples, we must handle the line values.
      // To handle the line values, the flags sub-sample must exist to cross-check for line presence and so on
      // (see these helpers: getVoltageValueAgainstFlags, getCurrentValueAgainstFlags).
      // Like seqNo, flags is sent only once over a period where more than one lines have been transmitted.
      // Therefore, we must populate the flags across the lines under its sequence.
      // ---
      // 2) Additionally we must backfill the _other_ line sub-samples to display them side-by-side in the SampleList,
      // i.e. all voltage lines together, or all current lines together.
      // ---
      // The following code populates the flags and backfills lines as needed, skipping if the sub-sample already exists.
      let sampleWithFlagsAndBackfilledLines: PowerMeterSampleWithKnownFlags | null = null;
      try {
        sampleWithFlagsAndBackfilledLines = populateFlagsAndBackfillLines(
          sample,
          samplesByDateUnordered,
          datesArrayNewestFirst,
          dateIndex
        );
      } catch (error) {
        if (error instanceof BackwardSearchExhaustedError) {
          rollbarLogger.info('[merge-power-meter-samples] Search for preceding sample exhausted', error);
        } else if (error instanceof ForwardSearchExhaustedError) {
          rollbarLogger.info('[merge-power-meter-samples] Search for following sample exhausted', error);
        } else if (error instanceof Error) {
          rollbarLogger.error('[merge-power-meter-samples] Error populating flags and backfilling lines', error);
        }
      }
      if (sampleWithFlagsAndBackfilledLines) {
        const sampleWithHandledLines = handleLineValuesAgainstFlags(sampleWithFlagsAndBackfilledLines);
        samplesByDateNewestFirst.set(date, sampleWithHandledLines);
      }
      return;
    }
    if (sample) {
      // No lines to be concerned with. The sample might have a flags sub-sample, or an energy sub-sample, or both.
      // But they do not need any special handling.
      samplesByDateNewestFirst.set(date, sample);
      return;
    }
  });

  return samplesByDateNewestFirst;
};