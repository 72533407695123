import cloneDeep from 'lodash/cloneDeep';

import { PowerMeterSample, PowerMeterSampleWithKnownFlags, PowerMeterSubSample } from 'types/models/power-meter';
import { SampleDate } from 'types/models/sample';
import { assertIsDefined } from 'utils/TypeScript/assert-is-defined';

const checkForVoltageKeysNeedingBackfill = (sample: PowerMeterSample): ('line1Voltage' | 'line2Voltage' | 'line3Voltage')[] => {
  const keysNeedingBackfill: ('line1Voltage' | 'line2Voltage' | 'line3Voltage')[] = [];
  if (!sample.line1Voltage) {
    keysNeedingBackfill.push('line1Voltage');
  }
  if (!sample.line2Voltage) {
    keysNeedingBackfill.push('line2Voltage');
  }
  if (!sample.line3Voltage) {
    keysNeedingBackfill.push('line3Voltage');
  }
  return keysNeedingBackfill;
};

const checkForCurrentKeysNeedingBackfill = (sample: PowerMeterSample): ('line1Current' | 'line2Current' | 'line3Current')[] => {
  const keysNeedingBackfill: ('line1Current' | 'line2Current' | 'line3Current')[] = [];
  if (!sample.line1Current) {
    keysNeedingBackfill.push('line1Current');
  }
  if (!sample.line2Current) {
    keysNeedingBackfill.push('line2Current');
  }
  if (!sample.line3Current) {
    keysNeedingBackfill.push('line3Current');
  }
  return keysNeedingBackfill;
};

class SearchExhaustedError extends Error {
  constructor(
    direction: 'Forward' | 'Backward',
    key: ForwardSearchKey | BackwardSearchKey,
    currentSample: PowerMeterSample,
    remainders: PowerMeterSample[]
  ) {
    const currentSampleDescription: string = SearchExhaustedError.getSampleDescription(currentSample);
    const remaindersDescription: string = remainders.map(SearchExhaustedError.getSampleDescription).join(';');
    const relational: 'following' | 'preceding' = direction === 'Forward' ? 'following' : 'preceding';
    const message = `No ${relational} sample matched for key "${key}". ` +
      `Our current sample is ${currentSampleDescription}. ` +
      `The remaining items are ${remaindersDescription}.`;

    super(message);
    this.name = `${direction}SearchExhaustedError`;
  }

  private static getSampleDescription = (s: PowerMeterSample): string =>
    `{date:${s.date},${Object.keys(s).filter(k => k !== 'date').join(',')}}`;
}

export class ForwardSearchExhaustedError extends SearchExhaustedError {
  constructor(key: ForwardSearchKey, currentSample: PowerMeterSample, remainders: PowerMeterSample[]) {
    super('Forward', key, currentSample, remainders);
  }
}

export class BackwardSearchExhaustedError extends SearchExhaustedError {
  constructor(key: BackwardSearchKey, currentSample: PowerMeterSample, remainders: PowerMeterSample[]) {
    super('Backward', key, currentSample, remainders);
  }
}

type ForwardSearchKey = keyof Pick<PowerMeterSample, 'flags'>;

const searchForFollowingSubSample = (
  key: ForwardSearchKey,
  samplesByDateUnordered: Map<SampleDate, PowerMeterSample>,
  datesArrayNewestFirst: SampleDate[],
  currentDateIndex: number
): { result: PowerMeterSubSample | null, visitedItems: PowerMeterSample[] } => {
  const visitedItems: PowerMeterSample[] = [];
  for (let i = currentDateIndex - 1; i >= 0; i--) {
    const sample = samplesByDateUnordered.get(datesArrayNewestFirst[i]);
    if (sample?.[key]) {
      const subSample = sample[key];
      assertIsDefined(subSample); // Assert here because TypeScript cannot infer from the if condition
      return { result: subSample, visitedItems };
    }
    if (sample) visitedItems.push(sample);
  }
  return { result: null, visitedItems };
};

type BackwardSearchKey = keyof Pick<PowerMeterSample, 'line1Voltage' | 'line1Current' | 'line2Voltage' | 'line2Current' | 'line3Voltage' | 'line3Current'>;

const searchForPrecedingSubSample = (
  key: BackwardSearchKey,
  samplesByDateUnordered: Map<SampleDate, PowerMeterSample>,
  datesArrayNewestFirst: SampleDate[],
  currentDateIndex: number
): { result: PowerMeterSubSample | null, visitedItems: PowerMeterSample[] } => {
  const visitedItems: PowerMeterSample[] = [];
  for (let i = currentDateIndex + 1; i < datesArrayNewestFirst.length; i++) {
    const sample = samplesByDateUnordered.get(datesArrayNewestFirst[i]);
    if (sample?.[key]) {
      const subSample = sample[key];
      assertIsDefined(subSample); // Assert here because TypeScript cannot infer from the if condition
      return { result: subSample, visitedItems };
    }
    if (sample) visitedItems.push(sample);
  }
  return { result: null, visitedItems };
};

const prepareSubSampleForCopy = (originalSubSample: PowerMeterSubSample): PowerMeterSubSample => {
  // seqNo is attached to the latest sub-interval, e.g. during the transmission interval [T-3, T], there were
  // three sub-intervals [T-3, T-2], [T-2, T-1], and [T-1, T]. The seqNo is only attached to [T-1, T], because the
  // transmission takes place at T. When copying subSample, we must scrub out the seqNo.
  // Document explaining this: https://farmbot-au.atlassian.net/wiki/spaces/DEV/pages/2502295562/Power+Meter+Samples+Time-Based+Relation#Between-the-rows
  const clone = { ...originalSubSample, extraValues: { seqNo: null } };
  return clone;
};

export const populateFlagsAndBackfillLines = (
  sample: PowerMeterSample,
  samplesByDateUnordered: Map<SampleDate, PowerMeterSample>,
  datesArrayNewestFirst: SampleDate[],
  dateIndex: number
): PowerMeterSampleWithKnownFlags => {
  const result = cloneDeep(sample) as PowerMeterSampleWithKnownFlags;

  // Populate the flags as needed.
  if (!sample.flags) {
    const { result: followingFlags, visitedItems } = searchForFollowingSubSample(
      'flags', samplesByDateUnordered, datesArrayNewestFirst, dateIndex
    );
    if (!followingFlags) {
      // Throw because we cannot guarantee the correct shape of data being returned
      throw new ForwardSearchExhaustedError('flags', result, visitedItems);
    }
    const flagsClone: PowerMeterSubSample = prepareSubSampleForCopy(followingFlags);
    result.flags = flagsClone;
  }

  const keysNeedingBackfill: BackwardSearchKey[] = [];
  const NO_NEED_TO_BACKFILL_LINES = 0;
  const IMPOSSIBLE_TO_BACKFILL_LINES = 3;

  const voltageKeysNeedingBackfill = checkForVoltageKeysNeedingBackfill(sample);
  if (
    voltageKeysNeedingBackfill.length !== NO_NEED_TO_BACKFILL_LINES
    && voltageKeysNeedingBackfill.length !== IMPOSSIBLE_TO_BACKFILL_LINES
  ) {
    keysNeedingBackfill.push(...voltageKeysNeedingBackfill);
  }

  const currentKeysNeedingBackfill = checkForCurrentKeysNeedingBackfill(sample);
  if (
    currentKeysNeedingBackfill.length !== NO_NEED_TO_BACKFILL_LINES
    && currentKeysNeedingBackfill.length !== IMPOSSIBLE_TO_BACKFILL_LINES
  ) {
    keysNeedingBackfill.push(...currentKeysNeedingBackfill);
  }

  // Backfill the lines, if any.
  keysNeedingBackfill.forEach(key => {
    const { result: precedingLine, visitedItems } = searchForPrecedingSubSample(
      key, samplesByDateUnordered, datesArrayNewestFirst, dateIndex
    );
    if (!precedingLine) {
      // Throw because we cannot guarantee the correct shape of data being returned
      throw new BackwardSearchExhaustedError(key, result, visitedItems);
    }
    const lineClone: PowerMeterSubSample = prepareSubSampleForCopy(precedingLine);
    result[key] = lineClone;
  });

  return result;
};