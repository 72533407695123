import SamplePointStatistic, { PowerMeterStatistic } from 'types/models/samplePointsStatistic';
import { assertIsSamplePointStatisticWithAggregates } from 'utils/SamplePointStatistics/assert-is-with-aggregates';

export function assertIsPowerMeterStatistic(s: SamplePointStatistic): asserts s is PowerMeterStatistic {
  if (!(
    'energy' in s
    && 'line1Voltage' in s
    && 'line1Current' in s
    && 'line2Voltage' in s
    && 'line2Current' in s
    && 'line3Voltage' in s
    && 'line3Current' in s
  )) {
    throw new Error(`Expected a PowerMeterStatistic but received ${JSON.stringify(s)}`);
  }

  assertIsSamplePointStatisticWithAggregates(s.energy);
}