import Sample, { SampleDate } from './sample';

export enum PowerMeterChannel {
  LINE_1_VOLTAGE = 3,
  LINE_1_CURRENT = 4,
  LINE_2_VOLTAGE = 5,
  LINE_2_CURRENT = 6,
  LINE_3_VOLTAGE = 7,
  LINE_3_CURRENT = 8,
  ENERGY = 2,
  FLAGS = 9
}

export enum PowerMeterStatus {
  OFFLINE = 'offline',
  ONLINE = 'online'
}

export type PowerLine = {
  lineNumber: 1 | 2 | 3;
  current: LineValue;
  voltage: LineValue;
};

export type PowerMeterSubSample = Pick<Sample,
  | 'rwValue'
  | 'prevDate'
  | 'extraValues'
>;

/**
 * Diagram showing how we arrive at this shape of data:
 * https://farmbot-au.atlassian.net/wiki/spaces/DEV/pages/2304049165/Power+Meter+data+structures+in+MFB+BE+FE#4.-FE---Merge-samples
 */
export type PowerMeterSample = { date: SampleDate } & Partial<{
  flags: PowerMeterSubSample,
  energy: PowerMeterSubSample,
  line1Voltage: PowerMeterSubSample,
  line1Current: PowerMeterSubSample,
  line2Voltage: PowerMeterSubSample,
  line2Current: PowerMeterSubSample,
  line3Voltage: PowerMeterSubSample,
  line3Current: PowerMeterSubSample
}>;

export type PowerMeterSampleWithKnownFlags = PowerMeterSample & { flags: PowerMeterSubSample };

export type LineValue = number | null;
