import round from 'lodash/round';

import { PERCENTAGE_PRECISIONS, PrecisionType } from 'constants/precision';

interface Options {
  decimalPlaces?: number;
}

/**
 * To calculate percentage. Default to 0 decimal places.
 */
function percentage(
  value?: number | null,
  maxValue?: number,
  options?: Options
) {
  const decimalPlaces = options?.decimalPlaces ?? PERCENTAGE_PRECISIONS[PrecisionType._DEFAULT];

  if (!value || !maxValue) {
    return 0;
  }

  const result = (value / maxValue) * 100;

  return round(result, decimalPlaces);
}

export default percentage;
