import { AssetTypeCode, weatherStationRawAssetTypes } from 'types/models/asset-type';
import SamplePoint, { MergedSamplePoint, MergedWeatherStationSamplePoint, RawSamplePoint, SensorTagId, WeatherStationAssociatedSamplePoints } from 'types/models/samplePoint';

import { type HiddenKeyExcludingController, getHiddenKey } from './hidden-key-to-weather-asset-type';
import parseRawSamplePoint from './parse-raw-samplepoint';

/**
 * Returns true if a weather station controller and a weather station sample point (rawSamplePoint) are associated.
 */
const isMatchingWeatherStationControllerAndSamplePoint = (
  rawSamplePoint: RawSamplePoint,
  accumulatedSP: SamplePoint
) => {
  const isWeatherStationController = accumulatedSP.assetTypeId === AssetTypeCode.WEATHER_STATION_CONTROLLER;
  const isWeatherStation = weatherStationRawAssetTypes.includes(rawSamplePoint.assetTypeId);
  const isOnSameMonitor = rawSamplePoint.deviceId === accumulatedSP.deviceId;

  return isWeatherStationController && isWeatherStation && isOnSameMonitor;
};

/** Merge incoming raw sample point into weather station controller (accumulatedSP) */
const mergeWeatherStationSamplePoints = (
  rawWSSamplePoint: RawSamplePoint,
  accumulatedSP: SamplePoint
): MergedWeatherStationSamplePoint => {
  const parsedSP: SamplePoint | null = parseRawSamplePoint(rawWSSamplePoint);
  if (!parsedSP) return accumulatedSP;

  const isMFBRainGauge = parsedSP.sensorTags.id === SensorTagId.RAIN_GAUGE;
  if (isMFBRainGauge) return accumulatedSP;

  const weatherStationAssetKey: HiddenKeyExcludingController | undefined = getHiddenKey(parsedSP.assetTypeId);
  if (!weatherStationAssetKey) return accumulatedSP;

  const mergedSP: MergedWeatherStationSamplePoint = accumulatedSP;
  mergedSP._hidden = {
    ...(mergedSP as MergedWeatherStationSamplePoint)._hidden, // Copy the existing associated sample points
    [weatherStationAssetKey]: parsedSP
  };
  return mergedSP;
};

/** Get Sample Point of Type in the same device from array of sample points */
const getSamplePointOfTypeInSameDevice = (
  samplePoints: SamplePoint[],
  assetTypeId: AssetTypeCode,
  deviceId: number
) => {
  const matchingSamplePoints = samplePoints.filter(
    sp => sp.assetTypeId === assetTypeId && sp.deviceId === deviceId
  );

  if (matchingSamplePoints.length === 0) return undefined;
  if (matchingSamplePoints.length === 1) return matchingSamplePoints[0];

  // Special Case: find the sensor with the latest last sample date in case of multiple sensors of the same type
  // in the same device. This is to handle the case where a sensor is replaced by another sensor or a data issue.
  return matchingSamplePoints.sort((a, b) => {
    const dateA = a.lastSampleDate ?? 0;
    const dateB = b.lastSampleDate ?? 0;
    return dateB - dateA;
  })[0];
};

/** Update the associated sample point in-place for the weather station controller */
const updateAssociatedSamplePointInWeatherStationController = (
  mergedSPs: MergedSamplePoint<SamplePoint | WeatherStationAssociatedSamplePoints>[],
  updatedSP: MergedWeatherStationSamplePoint
): void => {
  const controllerSPIndex: number = mergedSPs.findIndex(
    (mergedSP) => mergedSP.id === updatedSP._hidden?.controller?.id
  );
  const weatherStationAssetKey: HiddenKeyExcludingController | undefined = getHiddenKey(updatedSP.assetTypeId);
  if (controllerSPIndex !== -1 && weatherStationAssetKey) {
    mergedSPs[controllerSPIndex]._hidden = {
      ...mergedSPs[controllerSPIndex]._hidden,
      [weatherStationAssetKey]: updatedSP
    };
  }
};

/**
 * It takes an array of parsed samplePoints and a raw samplePoint, and returns an array of (merged) parsed samplePoints.
 * Should handle multiple cases for adding context via hidden property.
 * Case 1: WS Controller should have a reference to all its associated sensors (but not itself).
 * Case 2: WS associated sensors should have a reference to the controller.
 * Case 3.1: Temperature sensor should have a reference to its paired Dew Point sensor.
 * Case 3.2: Dew Point sensor should have a reference to its paired Temperature sensor.
 */
export const weatherStationSamplePointReducer = (
  accumulatedSamplePoints: SamplePoint[],
  currentWSSamplePoint: RawSamplePoint
) => {
  const nextSamplePoints: MergedSamplePoint<SamplePoint | WeatherStationAssociatedSamplePoints>[] = [];

  // Scan through the accumulated parsed sample points, if currentWSSamplePoint pairs with an accumulated sample point,
  // merge them. Otherwise, keep copying SP from accumulatedSamplePoints to nextSamplePoints array.
  for (const accumulatedSP of accumulatedSamplePoints) {
    // Case 1
    if (isMatchingWeatherStationControllerAndSamplePoint(currentWSSamplePoint, accumulatedSP)) {
      nextSamplePoints.push(mergeWeatherStationSamplePoints(currentWSSamplePoint, accumulatedSP));
    } else {
      nextSamplePoints.push(accumulatedSP);
    }
  }

  const parsedWSSamplePoint = parseRawSamplePoint(currentWSSamplePoint) as MergedWeatherStationSamplePoint;
  let hasBeenUpdated: boolean = false;
  if (!parsedWSSamplePoint) return nextSamplePoints;

  // Case 2
  const controllerForCurrentWSSamplePoint = getSamplePointOfTypeInSameDevice(
    accumulatedSamplePoints,
    AssetTypeCode.WEATHER_STATION_CONTROLLER,
    parsedWSSamplePoint.deviceId
  );

  if (controllerForCurrentWSSamplePoint
    && parsedWSSamplePoint.assetTypeId !== AssetTypeCode.WEATHER_STATION_CONTROLLER
  ) {
    parsedWSSamplePoint._hidden = {
      ...parsedWSSamplePoint._hidden,
      controller: controllerForCurrentWSSamplePoint
    };
    hasBeenUpdated = true;
  }

  // Case 3.1
  if (parsedWSSamplePoint.assetTypeId === AssetTypeCode.TEMPERATURE) {
    const dewPointSP = getSamplePointOfTypeInSameDevice(
      accumulatedSamplePoints,
      AssetTypeCode.DEW_POINT,
      parsedWSSamplePoint.deviceId
    );

    if (dewPointSP) {
      parsedWSSamplePoint._hidden = {
        ...parsedWSSamplePoint._hidden,
        dewPoint: dewPointSP
      };
      hasBeenUpdated = true;
    }
  }

  // Case 3.2
  if (parsedWSSamplePoint.assetTypeId === AssetTypeCode.DEW_POINT) {
    const temperatureSP = getSamplePointOfTypeInSameDevice(
      accumulatedSamplePoints,
      AssetTypeCode.TEMPERATURE,
      parsedWSSamplePoint.deviceId
    );

    if (temperatureSP) {
      parsedWSSamplePoint._hidden = {
        ...parsedWSSamplePoint._hidden,
        temperature: temperatureSP
      };
      hasBeenUpdated = true;
    }
  }

  if (hasBeenUpdated) {
    updateAssociatedSamplePointInWeatherStationController(nextSamplePoints, parsedWSSamplePoint);
  }

  // Unlike other sensor's merging logic, weather station's associated sample points still need to be available in the
  // store, so we always push them to nextSamplePoints array.
  nextSamplePoints.push(parsedWSSamplePoint);

  return nextSamplePoints;
};
