import {
  DEFAULT_PRECISION_IMPERIAL,
  DEFAULT_PRECISION_METRIC
} from 'constants/samplePoint';
import {
  UnitElectricCurrent,
  UnitEnergyFlux,
  UnitEnergyUsage,
  UnitPressureLg,
  UnitPressureMd,
  UnitRain,
  UnitTemperature,
  UnitVoltage,
  UnitWindSpeed
} from 'utils/get-unit-by-country';

/** Doc: https://farmbot-au.atlassian.net/wiki/spaces/DEV/pages/2267938817/Product+Spec+Weather+Stations#Weather-Sensor-Decimal-Placement-(precision) */
const ENERGY_UNIT_SYMBOL_TO_PRECISION: Record<
  UnitElectricCurrent | UnitEnergyFlux | UnitEnergyUsage | UnitVoltage,
  number
> = {
  'A': 1,
  'V': 2,
  'W/m²': 1,
  'kWh': 2
};

/** Doc: https://farmbot-au.atlassian.net/wiki/spaces/DEV/pages/2267938817/Product+Spec+Weather+Stations#Weather-Sensor-Decimal-Placement-(precision) */
const PRESSURE_UNIT_SYMBOL_TO_PRECISION: Record<UnitPressureMd | UnitPressureLg, number> = {
  'hPa': 0,
  'inHg': 2,
  'kPa': DEFAULT_PRECISION_METRIC,
  'psi': DEFAULT_PRECISION_IMPERIAL
};

/** Doc: https://farmbot-au.atlassian.net/wiki/spaces/DEV/pages/2267938817/Product+Spec+Weather+Stations#Weather-Sensor-Decimal-Placement-(precision) */
const SPEED_UNIT_SYMBOL_TO_PRECISION: Record<UnitWindSpeed, number> = {
  'km/h': 0,
  'mph': 1
};

/** Doc: https://farmbot-au.atlassian.net/wiki/spaces/DEV/pages/2267938817/Product+Spec+Weather+Stations#Weather-Sensor-Decimal-Placement-(precision) */
const RAIN_UNIT_SYMBOL_TO_PRECISION: Record<UnitRain, number> = {
  'in': 2,
  'mm': 1
};

/** Doc: https://farmbot-au.atlassian.net/wiki/spaces/DEV/pages/2267938817/Product+Spec+Weather+Stations#Weather-Sensor-Decimal-Placement-(precision) */
const TEMPERATURE_UNIT_SYMBOL_TO_PRECISION: Record<UnitTemperature, number> = {
  '°C': 1,
  '°F': 1
};

// Ideally, we should support all unit symbols. But given the legacy code, it will take a while to refactor.
// TODO: https://farmbot.atlassian.net/browse/FMBT-7270
type SupportedUnitSymbol = Exclude<
  | UnitElectricCurrent
  | UnitEnergyFlux
  | UnitEnergyUsage
  | UnitPressureLg
  | UnitPressureMd
  | UnitRain
  | UnitTemperature
  | UnitVoltage
  | UnitWindSpeed
  , '%'>;

export const UNIT_SYMBOL_TO_PRECISION: Record<SupportedUnitSymbol, number> = {
  ...ENERGY_UNIT_SYMBOL_TO_PRECISION,
  ...PRESSURE_UNIT_SYMBOL_TO_PRECISION,
  ...RAIN_UNIT_SYMBOL_TO_PRECISION,
  ...SPEED_UNIT_SYMBOL_TO_PRECISION,
  ...TEMPERATURE_UNIT_SYMBOL_TO_PRECISION
};

// ==============================
// A SPECIAL HANDLING FOR PERCENTAGE, FOR NOW. BECAUSE UNLIKE UNIT_SYMBOL_TO_PRECISION, IT HOLDS MORE THAN ONE PRECISION.
// ==============================
export enum PrecisionType {
  _DEFAULT = '_default',
  PERCENTAGE_DAM = 'percentage_dam',
  PERCENTAGE_DISTRIBUTION = 'percentage_distribution',
  PERCENTAGE_HUMIDITY = 'percentage_humidity'
} // Add more precision types here if needed; as of now, only percentage needs it.

type PercentagePrecisionType =
  | PrecisionType._DEFAULT
  | PrecisionType.PERCENTAGE_DAM
  | PrecisionType.PERCENTAGE_DISTRIBUTION
  | PrecisionType.PERCENTAGE_HUMIDITY;

/** Doc: https://farmbot-au.atlassian.net/wiki/spaces/DEV/pages/2267938817/Product+Spec+Weather+Stations#Weather-Sensor-Decimal-Placement-(precision) */
export const PERCENTAGE_PRECISIONS: Record<PercentagePrecisionType, number> = {
  [PrecisionType._DEFAULT]: 0,
  [PrecisionType.PERCENTAGE_DAM]: 2,
  [PrecisionType.PERCENTAGE_DISTRIBUTION]: 1,
  [PrecisionType.PERCENTAGE_HUMIDITY]: 1
};