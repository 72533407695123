import { AssetTypeCode } from 'types/models/asset-type';
import Sample, { type SampleDate } from 'types/models/sample';
import SamplePoint, { MergedWeatherStationSamplePoint, SamplePointId } from 'types/models/samplePoint';
import SamplePointStatistic from 'types/models/samplePointsStatistic';
import { WEATHER_ASSET_TYPE_TO_UNIT_TYPE, WeatherAssetType, WeatherUnitType } from 'types/weather.enum';
import { WindDirectionCategory } from 'types/wind.enum';
import { getAssociatedSamplePointsFromWeatherStationController } from 'utils/associated-sample-points/get-original-sample-point-ids-from-merged-sample-point';
import { convertMetricToImperialAlwaysRounding, isUnitTypeConvertible } from 'utils/convert-metric-to-imperial';
import { UnitType } from 'utils/get-unit-by-country';
import { getWindMeasurements } from 'utils/Sample/get-wind-measurements';
import { RAW_TO_PARSED_ASSET_TYPE_CODE } from 'utils/SamplePoints/asset-types/parse-raw-asset-types';

export const retrieveWeatherSummaryMetrics = (
  controllerSP: MergedWeatherStationSamplePoint,
  statisticsBySamplePointId: Record<number, SamplePointStatistic>,
  country: string
): SamplePoint['weatherStatistics'] | undefined => {
  const availableSamplePointIds: SamplePointId[] = getAssociatedSamplePointsFromWeatherStationController(controllerSP)
    .map((sp) => sp.id);
  if (!availableSamplePointIds.length) return;

  const availableStatistics: SamplePointStatistic[] = availableSamplePointIds
    .map((spId) => statisticsBySamplePointId[spId])
    .filter((statistic): statistic is SamplePointStatistic => !!statistic);

  const latestWeatherSamples: { assetTypeId: WeatherAssetType, sample: Sample }[] = availableStatistics
    .map((statistic) => ({
      assetTypeId: RAW_TO_PARSED_ASSET_TYPE_CODE[statistic.assetTypeId],
      sample: statistic.lastSample
    }))
    .filter((statistic): statistic is { assetTypeId: WeatherAssetType, sample: Sample } =>
      !!statistic.assetTypeId && !!statistic.sample
    );
  if (!latestWeatherSamples.length) return;

  let assetTypeIdLastSampled: WeatherAssetType | undefined;
  let lastSampleDate: SampleDate = 0;
  let lastSampleValueMetric: number = 0;
  let windMeasurements: ReturnType<typeof getWindMeasurements> | undefined;

  // Find the most recent sample among all latest samples
  for (const { assetTypeId, sample } of latestWeatherSamples) {
    if (sample.date > lastSampleDate) {
      lastSampleDate = sample.date;
      lastSampleValueMetric = sample.rwValue;
      assetTypeIdLastSampled = assetTypeId;
      if (assetTypeId === AssetTypeCode.WIND) windMeasurements = getWindMeasurements(sample);
    }
  }
  if (!assetTypeIdLastSampled) return;

  const unitType: WeatherUnitType = WEATHER_ASSET_TYPE_TO_UNIT_TYPE[assetTypeIdLastSampled];
  let lastSampleValueLocale: number = lastSampleValueMetric;
  if (isUnitTypeConvertible(unitType)) {
    lastSampleValueLocale = convertMetricToImperialAlwaysRounding(
      unitType,
      country,
      lastSampleValueMetric
    )!; // Won't be undefined because lastSampleValue is defined
  }

  let lastWindSampleValue: NonNullable<SamplePoint['weatherStatistics']>['lastWindSampleValue'];
  if (windMeasurements) {
    const speedLocale: number = convertMetricToImperialAlwaysRounding(
      UnitType.WIND_SPEED,
      country,
      windMeasurements.windSpeedMetric
    )!; // Won't be undefined because windSpeedMetric is defined
    const directionCategory: WindDirectionCategory = windMeasurements.windDirectionCategory;
    lastWindSampleValue = { speedLocale, directionCategory };
  }

  return { assetTypeIdLastSampled, lastSampleDate, lastSampleValueLocale, lastWindSampleValue };
};
