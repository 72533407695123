import {
  blue,
  darkGreen,
  darkOrange,
  darkPurple,
  darkRed,
  green,
  lightBlue,
  lighterBlue,
  lighterGreen,
  orange,
  purple,
  red,
  yellow
} from 'style/colour-palette';

export enum WindDirectionCategory {
  N = 'N',
  NNE = 'NNE',
  NE = 'NE',
  ENE = 'ENE',
  E = 'E',
  ESE = 'ESE',
  SE = 'SE',
  SSE = 'SSE',
  S = 'S',
  SSW = 'SSW',
  SW = 'SW',
  WSW = 'WSW',
  W = 'W',
  WNW = 'WNW',
  NW = 'NW',
  NNW = 'NNW'
}

/** Based on https://www.rmets.org/metmatters/beaufort-wind-scale */
export enum WindSpeedCategory {
  BEAUFORT_ZERO = '< 1 km/h',
  BEAUFORT_ONE = '1-5 km/h',
  BEAUFORT_TWO = '6-11 km/h',
  BEAUFORT_THREE = '12-19 km/h',
  BEAUFORT_FOUR = '20-28 km/h',
  BEAUFORT_FIVE = '29-38 km/h',
  BEAUFORT_SIX = '39-49 km/h',
  BEAUFORT_SEVEN = '50-61 km/h',
  BEAUFORT_EIGHT = '62-74 km/h',
  BEAUFORT_NINE = '75-88 km/h',
  BEAUFORT_TEN = '89-102 km/h',
  BEAUFORT_ELEVEN = '103-117 km/h',
  BEAUFORT_TWELVE = '≥ 118 km/h'
}

export const WIND_SPEED_TO_METRIC_LABEL: Record<WindSpeedCategory, string> = {
  [WindSpeedCategory.BEAUFORT_ZERO]: '< 1 km/h',
  [WindSpeedCategory.BEAUFORT_ONE]: '1-5 km/h',
  [WindSpeedCategory.BEAUFORT_TWO]: '6-11 km/h',
  [WindSpeedCategory.BEAUFORT_THREE]: '12-19 km/h',
  [WindSpeedCategory.BEAUFORT_FOUR]: '20-28 km/h',
  [WindSpeedCategory.BEAUFORT_FIVE]: '29-38 km/h',
  [WindSpeedCategory.BEAUFORT_SIX]: '39-49 km/h',
  [WindSpeedCategory.BEAUFORT_SEVEN]: '50-61 km/h',
  [WindSpeedCategory.BEAUFORT_EIGHT]: '62-74 km/h',
  [WindSpeedCategory.BEAUFORT_NINE]: '75-88 km/h',
  [WindSpeedCategory.BEAUFORT_TEN]: '89-102 km/h',
  [WindSpeedCategory.BEAUFORT_ELEVEN]: '103-117 km/h',
  [WindSpeedCategory.BEAUFORT_TWELVE]: '≥ 118 km/h'
};

export const WIND_SPEED_TO_IMPERIAL_LABEL: Record<WindSpeedCategory, string> = {
  [WindSpeedCategory.BEAUFORT_ZERO]: '< 1 mph',
  [WindSpeedCategory.BEAUFORT_ONE]: '1-3 mph',
  [WindSpeedCategory.BEAUFORT_TWO]: '4-7 mph',
  [WindSpeedCategory.BEAUFORT_THREE]: '8-12 mph',
  [WindSpeedCategory.BEAUFORT_FOUR]: '13-18 mph',
  [WindSpeedCategory.BEAUFORT_FIVE]: '19-24 mph',
  [WindSpeedCategory.BEAUFORT_SIX]: '25-31 mph',
  [WindSpeedCategory.BEAUFORT_SEVEN]: '32-38 mph',
  [WindSpeedCategory.BEAUFORT_EIGHT]: '39-46 mph',
  [WindSpeedCategory.BEAUFORT_NINE]: '47-54 mph',
  [WindSpeedCategory.BEAUFORT_TEN]: '55-63 mph',
  [WindSpeedCategory.BEAUFORT_ELEVEN]: '64-72 mph',
  [WindSpeedCategory.BEAUFORT_TWELVE]: '≥ 73 mph'
};

export const WIND_SPEED_TO_COLOUR: Record<WindSpeedCategory, string> = {
  [WindSpeedCategory.BEAUFORT_ZERO]: lighterBlue,
  [WindSpeedCategory.BEAUFORT_ONE]: lightBlue,
  [WindSpeedCategory.BEAUFORT_TWO]: blue,
  [WindSpeedCategory.BEAUFORT_THREE]: lighterGreen,
  [WindSpeedCategory.BEAUFORT_FOUR]: green,
  [WindSpeedCategory.BEAUFORT_FIVE]: darkGreen,
  [WindSpeedCategory.BEAUFORT_SIX]: yellow,
  [WindSpeedCategory.BEAUFORT_SEVEN]: orange,
  [WindSpeedCategory.BEAUFORT_EIGHT]: darkOrange,
  [WindSpeedCategory.BEAUFORT_NINE]: red,
  [WindSpeedCategory.BEAUFORT_TEN]: darkRed,
  [WindSpeedCategory.BEAUFORT_ELEVEN]: purple,
  [WindSpeedCategory.BEAUFORT_TWELVE]: darkPurple
};