import isNil from 'lodash/isNil';

import {
  AC_FT_TO_ML,
  AC_TO_HA,
  FT_2_TO_SQM,
  FT_TO_M,
  GAL_TO_L,
  IN_TO_CM,
  IN_TO_MM,
  PSI_TO_KPA
} from 'constants/unit-conversion';
import { UnitType } from 'utils/get-unit-by-country';
import roundNumber from 'utils/round-number';

// TODO: Merge this file with src/utils/convert-metric-to-imperial/index.ts
/** Convert imperial to metric if the input is from a US enterprise */
// TODO: Add unit testing @debt
export default function convertImperialToMetric(
  unitType:
    | UnitType.AREA_SM
    | UnitType.FLOW
    | UnitType.LEVEL_MD
    | UnitType.LEVEL_SM
    | UnitType.PRESSURE_LG
    | UnitType.RAIN
    | UnitType.SIZE
    | UnitType.TREND_SM
    | UnitType.VOLUME_LG
    | UnitType.VOLUME_SM,
  country: string,
  value?: number,
  decimals = 2
): number | undefined {
  if (isNil(value)) return value;
  if (value === 0) return 0;
  let convertedValue = +value;
  if (Number.isNaN(convertedValue)) {
    // eslint-disable-next-line no-console
    console.error('[convertImperialToMetric] Input is not a number', value);
    return 0;
  }
  if (country === 'USA') {
    switch (unitType) {
      case UnitType.LEVEL_SM:
      case UnitType.TREND_SM:
        convertedValue *= IN_TO_CM;
        break;
      case UnitType.LEVEL_MD:
        convertedValue *= FT_TO_M;
        break;
      case UnitType.SIZE:
        convertedValue *= AC_TO_HA;
        break;
      case UnitType.VOLUME_SM:
      case UnitType.FLOW:
        convertedValue *= GAL_TO_L;
        break;
      case UnitType.VOLUME_LG:
        convertedValue *= AC_FT_TO_ML;
        break;
      case UnitType.PRESSURE_LG:
        convertedValue *= PSI_TO_KPA;
        break;
      case UnitType.AREA_SM:
        convertedValue *= FT_2_TO_SQM;
        break;
      case UnitType.RAIN:
        convertedValue *= IN_TO_MM;
        break;
      default:
        break;
    }
  }
  return roundNumber(convertedValue, { decimalPlaces: decimals });
}
