import { PowerMeterChartAssetType } from 'components/features/samplePoint/PowerMeterSampleChart';
import { PowerMeterChartType } from 'components/features/samplePoint/SamplePointSampleChart/sampleChartType.enum';
import { RawAssetTypeCode } from 'types/models/asset-type';
import { TriggerType } from 'types/trigger.enum';

export const POWER_METER_CHART_TYPE_TO_TRIGGER_TYPE: Record<PowerMeterChartType, TriggerType[]> = {
  [PowerMeterChartType.VOLTAGE]: [
    TriggerType.POWER_METER_LINE_MISSING,
    TriggerType.POWER_METER_OVER_VOLTAGE,
    TriggerType.POWER_METER_UNDER_VOLTAGE,
    TriggerType.POWER_METER_LINE_VOLTAGE_MISMATCH
  ],
  [PowerMeterChartType.CURRENT]: [TriggerType.POWER_METER_LINE_CURRENT_MISMATCH],
  [PowerMeterChartType.ENERGY]: []
};

export const POWER_METER_ASSET_TYPE_TO_TRIGGER_TYPE: Record<PowerMeterChartAssetType, TriggerType[]> = {
  [RawAssetTypeCode.POWER_METER_CURRENT]: [TriggerType.POWER_METER_LINE_CURRENT_MISMATCH],
  [RawAssetTypeCode.POWER_METER_VOLTAGE]: [
    TriggerType.POWER_METER_OVER_VOLTAGE,
    TriggerType.POWER_METER_UNDER_VOLTAGE,
    TriggerType.POWER_METER_LINE_VOLTAGE_MISMATCH
  ],
  [RawAssetTypeCode.POWER_METER_ENERGY]: []
};

export const LINE_MISSING_INDICATOR: number = -1;
export const LINE_MISSING_DISPLAY: string = 'N/A';

/** This is likely a firmware bug */
export const DETECTED_BIT_AND_SAMPLE_RECEIVED_MISMATCH: number = -2;
export const DETECTED_BIT_AND_SAMPLE_RECEIVED_MISMATCH_DISPLAY: string = '?';
