import isNil from 'lodash/isNil';

import { EM_DASH } from 'constants/index';
import { PERCENTAGE_PRECISIONS, PrecisionType, UNIT_SYMBOL_TO_PRECISION } from 'constants/precision';
import roundNumber from 'utils/round-number';

interface Options {
  suffix?: string; // Ideally, should type this as UnitSymbol, not string
  /**
   * Some unit symbols hold more than one precision. In such cases, pass a `precisionType` to dictate the precision
   * you want to use. Otherwise, the function will go with the default precision for that unit symbol.
   */
  precisionType?: PrecisionType;
  noSuffixSpace?: boolean;
  /** Prefer passing `suffix` to determine decimalPlaces */
  decimalPlaces?: number;
}

/**
 * A function that helps achieve consistent formatting when displaying quantity
 * on the UI.
 *
 * Default to 1 decimal place.
 * Also, if the suffix is a unit symbol, unprovided decimalPlaces will default to the precision of that unit.
 */
function formatQuantity(value?: number | string | null, options?: Options): string {
  if (isNil(value)) {
    return EM_DASH;
  }
  let decimalPlaces: number | undefined = options?.decimalPlaces;
  let suffix: string | undefined = options?.suffix;
  if (suffix === '%') {
    const percentagePrecisionType: PrecisionType = options?.precisionType || PrecisionType._DEFAULT;
    decimalPlaces = decimalPlaces ?? PERCENTAGE_PRECISIONS[percentagePrecisionType];
  } else if (suffix) {
    decimalPlaces = decimalPlaces ?? UNIT_SYMBOL_TO_PRECISION[suffix];
  }

  const numberValue = Number(
    // Turn '1,234,567.89' into '1234567.89' to allow correct parsing.
    typeof value === 'string' ? value.replace(/,/g, '') : value
  );

  if (isNaN(numberValue)) {
    return EM_DASH;
  }

  if (suffix?.startsWith('day')) {
    suffix = numberValue === 1 ? 'day' : 'days';
  }

  const space = options?.noSuffixSpace || suffix === '%' || suffix?.startsWith('°')
    ? ''
    : ' ';

  return `${roundNumber(numberValue, { decimalPlaces }).toLocaleString()}${suffix ? `${space}${suffix}` : ''
    }`;
}

export default formatQuantity;
