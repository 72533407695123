import Sample from 'types/models/sample';

import { type Nibble, type PowerMeterFlagsNibbles } from './bit';
import { convertDecimalToBinary, isValidInt32 } from './int32-binary';

export const parsePowerMeterFlagsSample = (flagsSampleValue: Sample['rwValue']): PowerMeterFlagsNibbles | null => {
  if (!isValidInt32(flagsSampleValue)) {
    return null;
  }
  const binary = convertDecimalToBinary(flagsSampleValue);

  const [
    l1v,
    l1c,
    l2v,
    l2c,
    l3v,
    l3c,
    spare,
    device
  ] = binary.match(/.{4}/g) as Nibble[];

  return {
    l1v,
    l1c,
    l2v,
    l2c,
    l3v,
    l3c,
    spare,
    device
  };
};
