import invert from 'lodash/invert';

import { AssetTypeCode } from 'types/models/asset-type';
import { WeatherStationAssociatedSamplePoints } from 'types/models/samplePoint';
import { WeatherAssetType } from 'types/weather.enum';

/** The 'controller' key does not participate in most mapping logic */
export type HiddenKeyExcludingController = Exclude<keyof WeatherStationAssociatedSamplePoints, 'controller'>;

// Because of the limitation of lodash/invert, asset type code needs to be the value here. Otherwise, after inversion,
// the key (asset type code) will be a string.
const HIDDEN_KEY_TO_WEATHER_ASSET_TYPE: Record<HiddenKeyExcludingController, WeatherAssetType> = {
  'barometricPressure': AssetTypeCode.BAROMETRIC_PRESSURE,
  'battery': AssetTypeCode.WEATHER_STATION_BATTERY,
  'dewPoint': AssetTypeCode.DEW_POINT,
  'humidity': AssetTypeCode.HUMIDITY,
  'rainfall': AssetTypeCode.RAIN_GAUGE,
  'solarRadiation': AssetTypeCode.SOLAR_RADIATION,
  'temperature': AssetTypeCode.TEMPERATURE,
  'wind': AssetTypeCode.WIND
};

export const getAssetTypeId = (associatedHiddenKey: HiddenKeyExcludingController): WeatherAssetType => {
  return HIDDEN_KEY_TO_WEATHER_ASSET_TYPE[associatedHiddenKey];
};

export const getHiddenKey = (assetTypeId: AssetTypeCode): HiddenKeyExcludingController | undefined => {
  return invert(HIDDEN_KEY_TO_WEATHER_ASSET_TYPE)[assetTypeId] as HiddenKeyExcludingController | undefined;
};