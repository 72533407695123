import { message as antdMessage } from 'antd';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import { DAM_PRECISION } from 'constants/samplePoint';
import useTranslation from 'hooks/useTranslation';
import useAssetType from 'store/modules/assetTypes/hooks';
import { useEnterpriseCountry } from 'store/modules/enterprise/hooks';
import { ephemeralGetRequest } from 'store/modules/ephemeralRequest/actions';
import Sample from 'types/models/sample';
import SamplePoint, { VolumeDisplayUnit } from 'types/models/samplePoint';
import { DateRange } from 'types/models/time';
import convertMetricToImperial from 'utils/convert-metric-to-imperial';
import downloadFile from 'utils/download-file';
import { UnitType, getUnitSymbolByCountry } from 'utils/get-unit-by-country';
import { convertLitresTo } from 'utils/unit-conversions';
import useIsAdvancedDam from 'utils/use-is-advanced-dam';

import { constructSampleUrlForCSVExport } from './utils/construct-sample-url-for-csv-export';

import { CSV_DATE_FORMAT, CSV_FILE_NAME_DATE_FORMAT } from '.';

// Check more shared comments in useExportRainGaugeCsv
export default function useExportDamCsv(
  dateRange: DateRange,
  samplePoint: SamplePoint,
  setLoading: (loading: boolean) => void,
  onFinish?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const country = useEnterpriseCountry();
  const { id, name, config, siteTimezoneCode, assetTypeId } = samplePoint;
  const spIsAdvancedDam = useIsAdvancedDam(id);
  const assetType = useAssetType(assetTypeId);
  const { volumeDisplayUnit = VolumeDisplayUnit.ML } = config ?? {};

  if (!siteTimezoneCode) {
    antdMessage.error('Site time zone is not set');
    return;
  }

  return async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    try {
      const samples = await new Promise<Sample[]>((resolve, reject) => {
        setLoading(true);
        const url = constructSampleUrlForCSVExport(id, siteTimezoneCode, dateRange);
        dispatch(
          ephemeralGetRequest<Sample[]>(url, resolve, reject)
        );
      });

      const csvArray = samples
        .sort((a, b) => b.date - a.date)
        .map((s) => {
          let level: number | undefined = s.rwValue;
          if (
            config.liquidLevelMeasurementDirection === 'topDown' &&
            config.maxDepth
          ) {
            level = config.maxDepth - s.rwValue;
          }
          level = convertMetricToImperial(
            UnitType.LEVEL_SM,
            country,
            level,
            DAM_PRECISION
          );
          const levelRL = s.levelRl
            ? convertMetricToImperial(
              UnitType.LEVEL_MD,
              country,
              s.levelRl,
              DAM_PRECISION
            )
            : '';
          let volume;
          if (spIsAdvancedDam) {
            volume = convertLitresTo(
              s.volume,
              volumeDisplayUnit,
              DAM_PRECISION
            );
          } else if (config.maxVolume && config.maxDepth) {
            volume = config.maxVolume * (s.rwValue / config.maxDepth);
            volume = convertLitresTo(volume, volumeDisplayUnit, DAM_PRECISION);
          } else {
            volume = '';
          }
          const date = moment(s.date)
            .tz(siteTimezoneCode)
            .format(CSV_DATE_FORMAT);
          return `${date},${volume},${level},${levelRL}`;
        });
      const levelUnit = getUnitSymbolByCountry({
        unitType: UnitType.LEVEL_SM,
        country
      });
      const measurementDirection =
        config.liquidLevelMeasurementDirection === 'topDown'
          ? ' from top'
          : 'from bottom';
      const rlUnit = getUnitSymbolByCountry({ unitType: UnitType.LEVEL_MD, country });
      csvArray.unshift(
        `Date and Time,${volumeDisplayUnit},${levelUnit} ${measurementDirection},${t(
          'RL'
        )} (${rlUnit})`
      );

      downloadFile(
        `${name} ${assetType.name} ${dateRange[0].format(
          CSV_FILE_NAME_DATE_FORMAT
        )}-${dateRange[1].format(CSV_FILE_NAME_DATE_FORMAT)}.csv`,
        csvArray.join('\n'),
        'text/csv'
      );

      onFinish?.(e);
      antdMessage.success('CSV exported successfully');
    } catch (error) {
      antdMessage.error(
        `Error exporting CSV: ${error instanceof Error ? error.message : error
        }`
      );
    } finally {
      setLoading(false);
    }
  };
}
