import isNil from 'lodash/isNil';

import Sample from 'types/models/sample';

import { parsePowerMeterFlagsSample } from './flags-parser';
import { checkLinePresence } from './line-presence';

export const checkSupportedLinesAgainstFlags = (flagsSampleValue: Sample['rwValue']) => {
  const { l1c, l1v, l2c, l2v, l3c, l3v } = parsePowerMeterFlagsSample(flagsSampleValue) || {};
  const isL1CExpected = isNil(l1c) ? false : checkLinePresence(l1c).isExpectedPresent;
  const isL1VExpected = isNil(l1v) ? false : checkLinePresence(l1v).isExpectedPresent;
  const isL2CExpected = isNil(l2c) ? false : checkLinePresence(l2c).isExpectedPresent;
  const isL2VExpected = isNil(l2v) ? false : checkLinePresence(l2v).isExpectedPresent;
  const isL3CExpected = isNil(l3c) ? false : checkLinePresence(l3c).isExpectedPresent;
  const isL3VExpected = isNil(l3v) ? false : checkLinePresence(l3v).isExpectedPresent;

  const isLine1Supported: boolean = isL1CExpected && isL1VExpected;
  const isLine2Supported: boolean = isL2CExpected && isL2VExpected;
  const isLine3Supported: boolean = isL3CExpected && isL3VExpected;

  return {
    isLine1Supported,
    isLine2Supported,
    isLine3Supported
  };
};